import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";


@Component({
  selector: "app-live",
  templateUrl: "./live.component.html",
  styleUrls: ["./live.component.css"],
})
export class LiveComponent implements OnInit {
  url='';
  yt='https://www.youtube.com/embed/live_stream?channel=';
final=this.yt + this.url;
   updateValue(e){
    console.log(e.target.value)
    this.url = e.target.value;
  }

  constructor(private route: Router) {}
//add a target blank iframe not support webcam
  ngOnInit(): void {}
  /* ngAfterViewInit() {
     this.route.navigate(["https://atmvideochat.com/default.aspx"]);
    window.open("https://atmvideochat.com/default.aspx", "");
   } */
 } 
