import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Category } from 'src/app/core/models/category';
import { CoursesService } from 'src/app/shared/services/courses.service';

@Component({
  selector: 'dashboard-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class DashboardCarouselComponent implements OnInit {

  images: string[] = [
    'assets/images/carousel/slide-1.jpg',
    'assets/images/carousel/slide-5x.jpg',
    'assets/images/carousel/slide-6.jpg',
  ];

  categories: Category[];

  cards: any[] = [
    {
      image: 'assets/images/carousel/slider-math.jpg',
      title: 'Math',
      text: 'For all Levels'
    },
    {
      image: 'assets/images/carousel/slider-student.jpg',
      title: 'Computing',
      text: 'this is the description 2'
    },
    {
      image: 'assets/images/carousel/slider-chinese.jpg',
      title: 'English',
      text: 'All primary levels'
    },
    {
      image: 'assets/images/carousel/slider-math.jpg',
      title: 'Science',
      text: 'Primary 1 to 6'
    },
    {
      image: 'assets/images/carousel/slider-student.jpg',
      title: 'Chemistry',
      text: 'Secondary all levels'
    },
    {
      image: 'assets/images/carousel/slider-chinese.jpg',
      title: 'Malay Language',
      text: 'Beginner to Advance'
    }
  ];

  carouselOptions = {
    items: 4,
    loop: true,
    margin: 20,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
        mergeFit: true
      },
      600: {
        items: 3,
        mergeFit: true
      },
      1000: {
        items: 5,
        mergeFit: false
      }
    }
  };

  constructor(
    config: NgbCarouselConfig,
    private coursesService: CoursesService
  ) {
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
    config.interval = 5000;
    config.wrap = true;
    config.keyboard = true;
    config.pauseOnHover = false;
  }

  ngOnInit(): void {

    this.categories = this.coursesService.getAllCategories();

  }

}
