import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import "rxjs/add/operator/map";
import { PortalUser } from "../user.model";
import { LoginUser } from "../login.model";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { UserInfo } from "../userInfo.model";
import { User } from "../../firebase/auth.service";
import { map, withLatestFrom } from "rxjs/operators";

@Injectable()
export class UserService {
  readonly rootUrl = "https://edglob.net/eduapi";

  private missionAnnouncedSource = new Subject<string>();

  // Observable string streams
  missionAnnounced$ = this.missionAnnouncedSource.asObservable();

  // Service message commands
  announceMission(mission: string) {
    this.missionAnnouncedSource.next(mission);
  }

  public loggedIn: boolean;
  private currentUser = null;

  public currentUser$ = new BehaviorSubject<User>(this.currentUser);
  public currentUserInfo$ = new BehaviorSubject<any>(this.currentUser);
  constructor(private http: HttpClient) {
    const userInfo = JSON.parse(localStorage.getItem("edGlobCurrentUser"));
    const userDetails = JSON.parse(localStorage.getItem("edGlobuserInfo"));

    if (userInfo) {
      this.currentUserInfo$.next(userDetails);
      this.loggedIn = true;
    }
  }

  public getUser(id: any) {
    return this.http
      .get<any>(this.rootUrl + "/api/User/GetUserById?userId=" + id)
      .map((response) => {
        this.currentUserInfo$.next(response.UserList[0]);
        return response;
      });
  }

  public getUserDetails(id: any) {
    return this.http.get<any>(
      this.rootUrl + "/api/User/GetUserById?userId=" + id
    );
  }

  // getStudentsSearchedBySpecificRequest(request: any): Observable<any> {
  //   return this.http
  //     .get<any>(
  //       this.rootUrl + "/api/User/SearchStudents?specificRequest=" + request
  //     )
  //     .map((res) => {
  //       return res.UserList;
  //     });
  // }

  getStudentsSearchedByCountry(country: any, specificRequest: any): Observable<any> {
    
    return this.http
      .get<any>(
        this.rootUrl +
          "/api/User/SearchStudents?country=" +
          country +
          "&specificRequest=" +
          specificRequest
      )
      .map((res) => {
        return res.UserList;
      });
  }

  getStudentFilteredResults(country, specificRequest) {
    return this.getStudentsSearchedByCountry(country, specificRequest)
  }

  registerUser(user: PortalUser) {
    const body: PortalUser = {
      ID: 0,
      Email: user.Email,
      Password: user.Password,
      LoginID: user.LoginID,
      FirstName: user.FirstName,
      LastName: user.LastName,
      userType: user.userType,
      UserTypeID: user.UserTypeID,
      profileImage: user.profileImage,
      profileName: user.profileName,
    };

    return this.http.post(this.rootUrl + "/api/User/RegisterUser", body);
  }

  loginUser(user: LoginUser) {
    const httpParams = new HttpParams()
      .append("userid", user.userid)
      .append("password", user.password);

    const params: LoginUser = {
      userid: user.userid,
      password: user.password,
    };

    return this.http
      .post<any>(
        this.rootUrl +
          "/api/User/Login?userid=" +
          user.userid +
          "&password=" +
          user.password,
        null
      )
      .map((response) => {
        return response["UserInfo"];
      });
  }
  setLogIn() {
    this.loggedIn = true;
  }
  setLogOut() {
    this.loggedIn = false;
  }

  public getUsers(): Observable<UserInfo[]> {
    return this.http.get<UserInfo[]>(this.rootUrl + "/api/User/GetUserList");
  }

  public updateUserQualificataions(form, ID) {
    const garduateYear = parseInt(form.GraduateYear);
    let apiurl = "/api/User/UpdateQualification";
    if (form.QualID == 0) {
      apiurl = "/api/User/SaveQualification";
    }

    return this.http
      .post<any>(this.rootUrl + apiurl, {
        QualID: form.QualID,
        userid: ID,
        Qualification: form.Qualification,
        FieldStudy: form.FieldStudy,
        University: form.University,
        Country: form.Country,
        GraduateYear: garduateYear,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public deleteUserQualificataions(id) {
    return this.http
      .post<any>(
        this.rootUrl + `/api/User/DeleteQualification?qualId=${id}`,
        {}
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public updateUserAddress(form, ID) {
    console.log("form received", {
      AddrID: form.AddrID,
      userid: ID,
      BlockNo: form.BlockNo,
      RoomNo: form.RoomNo,
      Street: form.Street,
      ZipCode: form.ZipCode,
      City: form.City,
      Country: form.Country,
    });

    let apiurl = "/api/User/UpdateAddress";
    if (form.AddrID == 0) {
      apiurl = "/api/User/SaveAddress";
    }

    return this.http
      .post<any>(this.rootUrl + apiurl, {
        AddrID: form.AddrID,
        userid: ID,
        BlockNo: form.BlockNo,
        RoomNo: form.RoomNo,
        Street: form.Street,
        ZipCode: form.ZipCode,
        City: form.City,
        Country: form.Country,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public deleteUserAddress(id) {
    return this.http
      .post<any>(this.rootUrl + `/api/User/DeleteAddress?addrId=${id}`, {})
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public updateUserParticulars(url: string, form, ID) {
    return this.http
      .post<any>(this.rootUrl + "/api/User/" + url + "Particular", {
        userid: ID,
        AboutMe: form.AboutMe,
        Gender: form.Gender,
        DateOfBirth: form.DateOfBirth,
        Race: form.Race,
        Nationality: form.Nationality,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public updateProfilePicture(userid, image, name) {
    return this.http.post<any>(
      this.rootUrl +
        `/api/User/UploadProfile?userId=${userid}&profileImage=${image}&profileName=${name}`,
      {}
    );
  }

  public getCountriesList(): Observable<any> {
    return this.http.get("../../../../../assets/data/countries/countries.json");
  }

  updateUserLogin(data: any) {
    return this.http.post<any>(
      this.rootUrl + "/api/User/UpdateUserLogin",
      data
    );
  }

  addUserSpecificRequest(data: any) {
    return this.http.post<any>(
      this.rootUrl + "/api/User/SaveSpecialRequest",
      data
    );
  }

  updateUserSpecificRequest(data: any) {
    return this.http.post<any>(
      this.rootUrl + "/api/User/UpdateSpecialRequest",
      data
    );
  }

  deleteUserSpecificRequest(id) {
    return this.http.post<any>(
      this.rootUrl + `/api/User/DeleteSpecialRequest?id=${id}`,
      {}
    );
  }

  public getCountries(): Observable<any> {
    return this.http.get<any>(this.rootUrl + "/api/User/GetCountries");
  }

  public getCountriesUser(): Observable<any> {
    return this.http.get<any>(this.rootUrl + "/api/User/GetUserCountry");
  }
}
