import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Topic } from './topic.model';

@Injectable()
export class TopicService {

  readonly rootUrl = 'https://edglob.net/eduapi';

  constructor(private http: HttpClient) { }

  createTopic(topic : Topic ){
    const body: Topic = {
      ID: topic.ID,
      CourseId: topic.CourseId,
      CourseTopicDescription: topic.CourseTopicDescription,
      CourseTopicTitle: topic.CourseTopicTitle,
      TopicTime: topic.TopicTime
    }
    return this.http.post(this.rootUrl + '/api/Course/CreateCourseTopic', body);
  }

}