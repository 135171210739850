import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Lesson } from './lesson.model';

@Injectable()
export class LessonService {

  readonly rootUrl = 'https://edglob.net/eduapi';

  constructor(private http: HttpClient) { }

  createLesson(lesson : Lesson ){
    const body: Lesson = {
      ID: lesson.ID,
      CourseId: lesson.CourseId,
      CourseTopicId: lesson.CourseTopicId,
      Title: lesson.Title,
      Description: lesson.Description,
      lessonContent: lesson.lessonContent,
      UTCStartTime: lesson.UTCStartTime,
      UTCEndTime: lesson.UTCEndTime,
      ClassRoomNo: lesson.ClassRoomNo,
      ClassRoomLink: lesson.ClassRoomLink,
      timeRequire: lesson.timeRequire,
      FeatureImg: lesson.FeatureImg,
      uploadAtachment: lesson.uploadAtachment
      
    }
    return this.http.post(this.rootUrl + '/api/Course/CreateLesson', body);
  }
}