import { Component, OnInit } from '@angular/core';
import { Instructor } from 'src/app/core/models/instructor';
import { Course } from 'src/app/core/models/course';
import { ActivatedRoute } from '@angular/router';
import { CoursesService } from 'src/app/shared/services/courses.service';
import {UserService} from '../../../shared/services/user/authentication/user.service';

@Component({
  selector: 'app-hybrid-courses',
  templateUrl: './hybrid-courses.component.html',
  styleUrls: ['./hybrid-courses.component.css']
})
export class HybridCoursesComponent implements OnInit {

  teacher: Instructor;
  courses: Course[];
  user: any;

  constructor(
      private userService: UserService,
    private route: ActivatedRoute,
    private coursesService: CoursesService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params) => {
        const id = params.id;
        this.userService.getUserDetails(id).subscribe((response) => {
            if (response) {
                this.user = response.UserList[0];
                console.log(this.user)
            }
        });

        this.courses = [];
        this.coursesService.getCoursesByInstructorId(id).subscribe(
          (response: TeacherCoursesResponse) => {
            this.courses = response.CourseList;
          }
        );
        // this.teacher = this.courses[0].courseInstructor;
      }
    );
  }

}


export interface TeacherCoursesResponse {
  $id:        string;
  Result:     null;
  CourseList: any[];
}
