import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'
import { Lesson } from 'src/app/shared/services/lesson/lesson.model';
import { LessonService } from 'src/app/shared/services/lesson/lesson.service';

@Component({
  selector: 'app-create-lesson',
  templateUrl: './create-lesson.component.html',
  styleUrls: ['./create-lesson.component.css']
})
export class CreateLessonComponent implements OnInit {

  lesson: Lesson;

  constructor(private lessonService: LessonService, private toastr: ToastrService) { }

  ngOnInit() {
    this.resetForm();
  }

  
  resetForm(form?: NgForm) {
    const filling = Math.floor((Math.random() * 100) + 1);
    if (form != null)
      form.reset();
    this.lesson = {
      ID: 0,
      CourseId: filling,
      CourseTopicId: filling,
      Title: '',
      Description: '',
      lessonContent: '',
      UTCStartTime: '',
      UTCEndTime: '',
      ClassRoomNo: '',
      ClassRoomLink: '',
      timeRequire: null,
      FeatureImg: '',
      uploadAtachment: ''
    }
  }

  OnSubmit(form: NgForm) {
    this.lessonService.createLesson(form.value)
      .subscribe((data: any) => {
        console.log(data);
      });
  }
}