import { Component, OnInit } from '@angular/core';
import { companyDB } from '../../../shared/data/tables/company';
import { Router } from '@angular/router';
import { CoursesService } from 'src/app/shared/services/courses.service';


@Component({
  selector: 'app-teacher-course-list',
  templateUrl: './teacher-course-list.component.html',
  styleUrls: ['./teacher-course-list.component.scss']
})
export class TeacherCourseListComponent implements OnInit {

  public company = [];
  public selected = [];
  CoursesList = [];
  user = JSON.parse(localStorage.getItem("edGlobCurrentUser"));

  constructor( private router: Router, private courseService: CoursesService) {
    this.company = companyDB.data;
  }

  ngOnInit(){
    this.getCourses();
  }

  getCourses()
  {
    this.courseService.getCoursesByInstructorId(this.user.loggedUserId).subscribe(x => {
      this.CoursesList = x.CourseList;
      console.log(this.CoursesList)
    })
  }


  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  CreateCourse(){
    this.router.navigate(['/createcourse']);
  }

  editCourse(id){
    this.router.navigate([`/editcourse/${id}`]);
  }

  deleteCourse(id){
    this.courseService.deleteCourse(id).subscribe(x => {
      if(x == true){
        this.getCourses();
      }
     
    })
  }

}
