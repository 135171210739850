import { Component, OnInit } from '@angular/core';
import { Category } from 'src/app/core/models/category';
import { Course } from 'src/app/core/models/course';
import { ActivatedRoute } from '@angular/router';
import { CoursesService } from 'src/app/shared/services/courses.service';

@Component({
  selector: 'app-category-courses',
  templateUrl: './category-courses.component.html',
  styleUrls: ['./category-courses.component.css']
})
export class CategoryCoursesComponent implements OnInit {

  category: Category;
  courses: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private coursesService: CoursesService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params) => {
        let id = params.id;
         this.coursesService.getCoursesByCategoryId(id).subscribe(res => {
           this.courses = res.CourseList.filter(x => x.SubjectId == id);
           console.log(this.courses)
           this.category = this.courses[0].SubjectId;
         });
        
        
      }
    );
  }
}
