import { Component, OnInit } from '@angular/core';
import { Category } from 'src/app/core/models/category';
import { Course } from 'src/app/core/models/course';
import { Instructor } from 'src/app/core/models/instructor';
import { CoursesService } from 'src/app/shared/services/courses.service';

@Component({
  selector: 'app-all-courses',
  templateUrl: './all-courses.component.html',
  styleUrls: ['./all-courses.component.css']
})
export class AllCoursesComponent implements OnInit {

  filterByOptions = [
    'Recently Added',
    'Highest Rating First'
  ]

  selectedFilter: string = 'Recently Added';
  courses: Course[];

  categories: Category[];
  instructors: Instructor[];
  progressList = [
    '100%',
    '> 80%',
    '> 70%'
  ]

  constructor(public coursesService: CoursesService) { }

  ngOnInit(): void {
    this.categories = this.coursesService.getAllCategories();
    this.instructors = this.coursesService.getAllInstructor();
    this.coursesService.getAllCourses().subscribe(
      (response: CoursesResponse) => {
        this.courses = response.CourseList;
      }
    )
  }

}

export interface CoursesResponse {
  $id:        string;
  Result:     null;
  CourseList: Course[];
}
