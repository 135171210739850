import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  categories: string[] = [
    'Language',
    'Math',
    'Science',
    'Art',
    'Sport',
    'Skills/Technologies',
  ]

  posts = [
    { title: 'Lorel Ipsum Something ?', date: new Date('12-07-2020') },
    { title: 'Some random post', date: new Date('06-07-2020') },
    { title: 'About a random topic.', date: new Date('05-07-2020') },
  ]

  constructor() { }

  ngOnInit() { }

}
