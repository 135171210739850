import { Component, OnInit } from "@angular/core";
import { CoursesService } from "src/app/shared/services/courses.service";
import { AuthService } from "src/app/shared/services/firebase/auth.service";
import { HttpClient } from "@angular/common/http";
import { Course } from "src/app/core/models/course";
import { Router } from "@angular/router";

@Component({
  selector: "app-dashboard-stats",
  templateUrl: "./dashboard-stats.component.html",
  styleUrls: ["./dashboard-stats.component.css"],
})
export class DashboardStatsComponent implements OnInit {
  loggedUserFullName: string;
  loggedUserType: string;
  userDetails = JSON.parse(localStorage.getItem("edGlobuserInfo"));

  statsList = [
    {
      title: "My Messages",
      value: 3,
      icon: "mail",
      link: "See Messages",
      linkIcon: "fa fa-plus",
    },
    {
      title: "My Courses",
      value: 10,
      icon: "star",
      link: "View All",
      linkIcon: "fa fa-search",
    },
    {
      title: "Completed",
      value: 73,
      icon: "check-square",
      link: "View Orders",
      linkIcon: "fa fa-search",
    },
    {
      title: "Notifications",
      value: 12,
      icon: "help-circle",
      link: "View Notifications",
      linkIcon: "fa fa-search",
    },
  ];

  constructor(
    public courseService: CoursesService,
    private http: HttpClient,
    private authService: AuthService,
    private router: Router
  ) {}
  courses: Course[];
  yourcourses: Course[];
  ngOnInit(): void {
    this.getName();

    //Get Top Courses
    this.http
      .get("http://webapi.theworkpc.com:8080/api/Course/GetCourseList")
      .subscribe((response: CoursesResponse) => {
        this.courses = response.CourseList;
        //Get Your Courses
        this.yourcourses = this.courses.slice(0, 3);
      });
  }

  getName() {
    this.loggedUserFullName = sessionStorage.getItem("loggedUser");
    this.loggedUserType = sessionStorage.getItem("loggedUserType");
  }

  redirectToProfilePage(type: string) {
    if (type == "public"){
      if(this.userDetails.userType == 'Teacher'){
        this.router.navigate(["/teachers/"+this.userDetails.ID]);
      }

      if(this.userDetails.userType == 'Student'){
        this.router.navigate(["/students/"+this.userDetails.ID]);
      }
      
    }
    if (type == "private") this.router.navigate(["/edit-profile"]);
  }
}
export interface CoursesResponse {
  $id: string;
  Result: null;
  CourseList: Course[];
}
