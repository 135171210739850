export class companyDB {
    static data =  [{
        "ID": 1,
        "courseTitle": "Math k1 Advance",
        "TeacherId": 3,
        "TeacherName": "Ping Ping",
        "SubjectName": "science",
        "NoOfLessons": 10,
        "Enroll": 328,
        "Fee": 125,
        "StartDate": "2011/4/19"
    },
    {
        "ID": 2,
        "courseTitle": "Chinese kindergarten 1",
        "TeacherId": 3,
        "TeacherName": "Ping Ping",
        "SubjectName": "language",
        "NoOfLessons": 15,
        "Enroll": 308,
        "Fee": 105,
        "StartDate": "2011/4/19"
    },
    {
        "ID": 3,
        "courseTitle": "Chemistry Adavance",
        "TeacherId": 3,
        "TeacherName": "Ping Ping",
        "SubjectName": "science",
        "NoOfLessons": 15,
        "Enroll": 208,
        "Fee": 125,
        "StartDate": "2011/4/19"
    },
    {
        "ID": 4,
        "courseTitle": "Primary English / Hindi",
        "TeacherId": 3,
        "TeacherName": "Ping Ping",
        "SubjectName": "language",
        "NoOfLessons": 15,
        "Enroll": 208,
        "Fee": 125,
        "StartDate": "2011/4/19"
    },
    {
        "ID": 5,
        "courseTitle": "Essaie Writing",
        "TeacherId": 3,
        "TeacherName": "Ping Ping",
        "SubjectName": "language",
        "NoOfLessons": 20,
        "Enroll": 108,
        "Fee": 225,
        "StartDate": "2011/4/19"
    }
]
}