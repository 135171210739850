import { Injectable, HostListener } from "@angular/core";
import { BehaviorSubject, Observable, Subscriber } from "rxjs";
import { AuthService } from "./firebase/auth.service";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  usertype?: string;
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
  }

  public screenWidth: any;
  public collapseSidebar = true;
  public fullScreen = false;

  MENUITEMS: Menu[] = [
    {
      path: "",
      title: "Home",
      icon: "home",
      type: "link",
      bookmark: true,
      usertype: "all",
    },
    //teacher
    {
      path: "dashboard",
      title: "Dashboard",
      icon: "bar-chart-2",
      type: "link",
      bookmark: true,
      usertype: "Teacher",
    },  
    {
      path: "edit-profile",
      title: "My Profile",
      icon: "book-open",
      type: "link",
      bookmark: true,
      usertype: "Teacher",
    },
    {
      path: "teachers-courses",
      title: "Courses Creation",
      icon: "book",
      type: "link",
      bookmark: true,
      usertype: "Teacher",
    },
    {
      path: "video",
      title: "Private Classes",
      icon: "video",
      type: "link",
      bookmark: true,
      usertype: "Teacher",
    }, /*
    {
      path: "broad-cast",
      title: "Video BroadCast",
      icon: "video",
      type: "link",
      bookmark: true,
      usertype: "Teacher",
    }, */
    {
      path: "live",
      title: "Live Stream",
      icon: "video",
      type: "link",
      bookmark: true,
      usertype: "Teacher",
    },
   /*
    {
      path: "chat",
      title: "Chat",
      icon: "message-square",
      type: "link",
      bookmark: true,
      usertype: "Teacher",
    }, */
    {
      path: "students",
      title: "Students Database",
      icon: "users",
      type: "link",
      bookmark: true,
      usertype: "Teacher",
    },
    //students
    {
      path: "dashboard",
      title: "Dashboard",
      icon: "bar-chart-2",
      type: "link",
      bookmark: true,
      usertype: "Student",
    },
    {
      path: "edit-profile",
      title: "My Profile",
      icon: "book-open",
      type: "link",
      bookmark: true,
      usertype: "Student",
    },
    {
      path: "courses",
      title: "Courses Search",
      icon: "book",
      type: "link",
      bookmark: true,
      usertype: "Student",
    }, /*
    {
      path: "timeTable",
      title: "Time Table",
      icon: "clock",
      type: "link",
      bookmark: true,
      usertype: "Student",
    }, */
    {
      path: "video",
      title: "Private Classes",
      icon: "video",
      type: "link",
      bookmark: true,
      usertype: "Student",
    },
    {
      path: "live",
      title: "Live Stream",
      icon: "video",
      type: "link",
      bookmark: true,
      usertype: "Student",
    },
    /*{
      path: "broad-cast",
      title: "Video BroadCast",
      icon: "video",
      type: "link",
      bookmark: true,
      usertype: "Student",
    }, */
    {
      path: "teachers",
      title: "Mentors Database",
      icon: "users",
      type: "link",
      bookmark: true,
      usertype: "Student",
    }, /*
    {
      path: "chat",
      title: "Chat",
      icon: "message-square",
      type: "link",
      bookmark: true,
      usertype: "Student",
    },*/
    //admin
    {
      path: "courses",
      title: "My Courses",
      icon: "book",
      type: "link",
      bookmark: true,
      usertype: "admin",
    },
    {
      path: "social-app",
      title: "Social App",
      icon: "user",
      type: "link",
      bookmark: true,
      usertype: "admin",
    },
    {
      path: "user-message",
      title: "Inbox",
      icon: "book",
      type: "link",
      bookmark: true,
      usertype: "admin",
    },
    {
      path: "cart",
      title: "Cart",
      icon: "shopping-cart",
      type: "link",
      bookmark: true,
      usertype: "admin",
    },
    {
      path: "components",
      title: "Components",
      icon: "bar-chart-2",
      type: "link",
      bookmark: true,
      usertype: "admin",
    },
    {
      path: "components-advanced",
      title: "Advanced Components",
      icon: "bar-chart-2",
      type: "link",
      bookmark: true,
      usertype: "admin",
    },

    // Student

    // { path: 'students', title: 'My Students', icon: 'book', type: 'link', bookmark: true, usertype: 'teacher' },
    // { path: 'about-us', title: 'Communication', icon: 'message-circle', type: 'link', bookmark: true },
  ];

  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

  // Windows width
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }
}
