import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./auth/login/login.component";
import { ToastrModule } from "ngx-toastr";

import { AuthService } from "./shared/services/firebase/auth.service";
import { AdminGuard } from "./shared/guard/admin.guard";
import { SecureInnerPagesGuard } from "./shared/guard/SecureInnerPagesGuard.guard";
import { CookieService } from "ngx-cookie-service";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { environment } from "../environments/environment";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { FiltersComponent } from "./dashboard/components/filters/filters.component";
import { ImageUploaderComponent } from "./dashboard/components/image-uploader/image-uploader.component";
import { ImagePreviewerComponent } from "./dashboard/components/image-previewer/image-previewer.component";
import { DocumentPreviewerComponent } from "./dashboard/components/document-previewer/document-previewer.component";
import {
  NgbDropdownModule,
  NgbCarouselModule,
} from "@ng-bootstrap/ng-bootstrap";
import { DashboardCarouselComponent } from "./dashboard/components/carousel/carousel.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { DashboardCoursesListComponent } from "./dashboard/components/courses-list/courses-list.component";
import { HomeComponent } from "./dashboard/pages/home/home.component";
import { CourseOverviewComponent } from "./dashboard/pages/course-overview/course-overview.component";
import { TeachersListComponent } from "./dashboard/pages/teachers-list/teachers-list.component";
import { StudentsListComponent } from "./dashboard/pages/students/students-list.component";
import { TeacherCourseListComponent } from "./dashboard/pages/teacher-course-list/teacher-course-list.component";
import { HybridCoursesComponent } from "./dashboard/pages/teacher-courses/hybrid-courses.component";
import { AllCoursesComponent } from "./dashboard/pages/all-courses/all-courses.component";
import { CategoryCoursesComponent } from "./dashboard/pages/category-courses/category-courses.component";
import { NewsComponent } from "./dashboard/pages/news/news.component";
import { GalleryComponent } from "./dashboard/pages/gallery/gallery.component";
import { AboutUsComponent } from "./dashboard/pages/about-us/about-us.component";
import { ClassesComponent } from "./dashboard/pages/classes/classes.component";
import { BroadCastComponent } from './dashboard/pages/broad-cast/broad-cast.component';
import { ShopComponent } from "./dashboard/pages/shop/shop.component";
import { DashboardStatsComponent } from "./dashboard/pages/dashboard-stats/dashboard-stats.component";
import { CountToModule } from "angular-count-to";
import { EditProfileComponent } from "./auth/edit-profile/edit-profile.component";
import { DashboardComponentsComponent } from "./dashboard/pages/dashboard-components/dashboard-components.component";
import { EmailModule } from "./auth/email/email.module";
import { UsersModule } from "./dashboard/pages/users/users.module";
import { AdvancedComponentsComponent } from "./dashboard/pages/advanced-components/advanced-components.component";
import { ChatComponent } from "./dashboard/pages/chat/chat.component";
import { CartComponent } from "./dashboard/pages/cart/cart.component";
import { SocialAppComponent } from "./dashboard/pages/social-app/social-app.component";
import { CreateCourseComponent } from "./dashboard/pages/create-course/create-course.component";
import { AddTaskComponent } from "./dashboard/pages/add-task/add-task.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { AdminDashboardComponent } from "./dashboard/pages/admin-dashboard/admin-dashboard.component";
import { UserService } from "./shared/services/user/authentication/user.service";
import { CourseService } from "src/app/shared/services/course/course.service";
import { SignupPortalComponent } from "./auth/signup/signup-portal/signup-portal.component";
import { SignupStudentComponent } from "./auth/signup/signup-student/signup-student.component";
import { SignupTeacherComponent } from "./auth/signup/signup-teacher/signup-teacher.component";
import { UpdatePasswordComponent } from "./auth/edit-profile/update-password/update-password.component";
import { ReminderComponent } from "./auth/signup/reminder/reminder.component";
import { TeacherReminderComponent } from "./auth/signup/teacher-reminder/teacher-reminder.component";
import { MustMatchDirective } from "./auth/signup/helpers/must-match.directive";
import { CreateTopicComponent } from "./dashboard/pages/create-topic/create-topic.component";
import { TopicService } from "./shared/services/topic/topic.service";
import { CreateLessonComponent } from "./dashboard/pages/create-lesson/create-lesson.component";
import { LessonService } from "./shared/services/lesson/lesson.service";
import {
  EditCourseComponent,
  CreateLessonDialog,
} from "./dashboard/pages/edit-course/edit-course.component";
import { EditTopicComponent } from "./dashboard/pages/edit-course/edit-topic/edit-topic.component";
import { PaymentStatus } from "./dashboard/pages/payment-status/payment-status.component";
import { EditLessonComponent } from "./dashboard/pages/edit-course/edit-lesson/edit-lesson.component";
import { CKEditorModule } from "ngx-ckeditor";
import { VideoComponent } from "./dashboard/pages/video/video.component";
import { MaterialModule } from "./material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    FiltersComponent,
    ImageUploaderComponent,
    ImagePreviewerComponent,
    DocumentPreviewerComponent,
    DashboardCarouselComponent,
    DashboardCoursesListComponent,
    HomeComponent,
    
    CourseOverviewComponent,
    TeachersListComponent,
    StudentsListComponent,
    HybridCoursesComponent,
    TeacherCourseListComponent,
    AllCoursesComponent,
    CategoryCoursesComponent,
    NewsComponent,
    GalleryComponent,
    AboutUsComponent,
    ClassesComponent,
    BroadCastComponent,
    ShopComponent,
    DashboardStatsComponent,
    EditProfileComponent,
    DashboardComponentsComponent,
    AdvancedComponentsComponent,
    ChatComponent,
    CartComponent,
    SignupPortalComponent,
    SocialAppComponent,
    EditCourseComponent,
    EditTopicComponent,
    EditLessonComponent,
    CreateLessonDialog,
    CreateCourseComponent,
    AddTaskComponent,
    AdminDashboardComponent,
    SignupPortalComponent,
    SignupStudentComponent,
    SignupTeacherComponent,
    UpdatePasswordComponent,
    ReminderComponent,
    TeacherReminderComponent,
    MustMatchDirective,
    CreateTopicComponent,
    CreateLessonComponent,
    VideoComponent,
    PaymentStatus,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    ImageCropperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularMultiSelectModule,
    AngularFirestoreModule,
    NgbDropdownModule,
    NgbCarouselModule,
    CarouselModule,
    CountToModule,
    EmailModule,
    UsersModule,
    AngularEditorModule,
    MaterialModule,
    ReactiveFormsModule,
    CKEditorModule,
    FlexLayoutModule,
    NgxDocViewerModule,
  ],
  providers: [
    AuthService,
    AdminGuard,
    SecureInnerPagesGuard,
    CookieService,
    UserService,
    CourseService,
    TopicService,
    LessonService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
