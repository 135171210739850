import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/shared/services/user/authentication/user.service';
import { UserInfo } from 'src/app/shared/services/user/userInfo.model';

@Component({
  selector: 'app-signup-portal',
  templateUrl: './signup-portal.component.html',
  styleUrls: ['./signup-portal.component.css']
})
export class SignupPortalComponent implements OnInit {

  public users: any;
  public UserList: Array<UserInfo[]>;
  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.getUsers();
  }


  public getUsers(): void {
    this.userService.getUsers().subscribe(
      (response: UserInfo[]) => {
        this.users = response;
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      }
    );
  }
}
