import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import "rxjs/add/operator/map";
import { Course } from "./course.model";

@Injectable()
export class CourseService {
  readonly rootUrl = "https://edglob.net/eduapi";

  constructor(private http: HttpClient) {}

  createCourse(body: any) {
    return this.http.post<any>(this.rootUrl + "/api/Course/CreateCourse", body);
  }

  updateCourse(body: any) {
    return this.http.post<any>(this.rootUrl + "/api/Course/UpdateCourse", body);
  }

  updateTopic(body: any) {
    return this.http.post<any>(
      this.rootUrl + "/api/Course/UpdateCourseTopic",
      body
    );
  }

  updateLesson(body: any) {
    return this.http.post<any>(this.rootUrl + "/api/Course/UpdateLesson", body);
  }

  getCourseDetails(id) {
    return this.http.get<any>(
      this.rootUrl + `/api/Course/GetCourseDetailsById?courseId=${id}`
    );
  }

  createCourseTopic(body) {
    return this.http.post<any>(
      this.rootUrl + `/api/Course/CreateCourseTopic`,
      body
    );
  }

  deleteCourseTopic(id) {
    return this.http.post<any>(
      this.rootUrl + `/api/Course/DeleteCourseTopic?courseTopicId=${id}`,
      {}
    );
  }

  deleteLeson(id) {
    return this.http.post<any>(
      this.rootUrl + `/api/Course/DeleteLesson?lessonId=${id}`,
      {}
    );
  }

  createLesson(body) {
    return this.http.post<any>(this.rootUrl + `/api/Course/CreateLesson`, body);
  }

  saveHomework(body) {
    return this.http.post<any>(
      this.rootUrl + `/api/Course/SaveLessonUpload`,
      body
    );
  }

  saveCourseAssets(imageUrl, courseId) {
    var completeUrl = `https://edglob2021.s3.ap-southeast-1.amazonaws.com/courses/${imageUrl}`;
    return this.http.post<any>(
      `${this.rootUrl}/api/Course/SaveCourseImage?courseId=${courseId}&ImageURL=${imageUrl}`,
      JSON.stringify({})
    );
  }
}
