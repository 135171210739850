import { Injectable } from "@angular/core";
import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class S3uploadService {
  constructor(private http: HttpClient) {}

  async uploadFile(file, foldername: string, filename?: string): Promise<any> {
    console.log("file upload started");
    const contentType = file.type;

    const bucket = new S3({
      accessKeyId: "AKIA6FYXKEQZ34VSD47X",
      secretAccessKey: "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",
      region: "ap-southeast-1",
    });
    var file_name = "";
    if (filename) file_name = filename;
    else file_name = (new Date().getTime() / 1000).toFixed(0) + "_" + file.name;

    const params = {
      Bucket: "edglob2021",
      Key: foldername + "/" + file_name,
      Body: file,
      ACL: "public-read",
      ContentType: contentType,
    };

    try {
      const data = await bucket.upload(params).promise();

      console.log("file upload ended");

      return {
        status: true,
        data: data,
      };
    } catch (err) {
      console.error(err);
      throw err;
    }

    /*
    bucket.upload(params, function (err, data) {
      if (err) {
        debugger
        console.log("There was an error uploading your file: ", err);
        response =  false;
        completed = true;
      }
      console.log("Successfully uploaded file.", data);
      debugger
      response =  true;
      completed = true;
    });
*/

    //for upload progress

    /*
    bucket
      .upload(params)
      .on("httpUploadProgress", function (evt) {
        console.log(evt.loaded + " of " + evt.total + " Bytes");
      })
      .send(function (err, data) {
        if (err) {
          console.log("There was an error uploading your file: ", err);
          return false;
        }
        console.log("Successfully uploaded file.", data);
        return true;
      });

      */
  }

  async uploadCropedImage(
    file,
    foldername: string,
    filename?: string
  ): Promise<any> {
    var file_ = this.dataURLtoFile(file, filename);
    file = file_;
    console.log("file upload started");
    const contentType = file.type;

    const bucket = new S3({
      accessKeyId: "AKIA6FYXKEQZ34VSD47X",
      secretAccessKey: "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",
      region: "ap-southeast-1",
    });
    // var file_name = "";
    // if (filename) file_name = filename;
    // else file_name = (new Date().getTime() / 1000).toFixed(0) + "" + file.name;

    const params = {
      Bucket: "edglob2021",
      Key: foldername + "/" + file_.name,
      Body: file,
      ACL: "public-read",
      ContentType: contentType,
    };

    try {
      const data = await bucket.upload(params).promise();

      console.log("file upload ended");

      return {
        status: true,
        data: data,
      };
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  async uploadCourseVideo(
    file,
    foldername: string,
    fileName: string
  ): Promise<any> {
    var file_ = this.dataURLtoFile(file, fileName);
    file = file_;
    console.log("file upload started");
    const contentType = file.type;

    const bucket = new S3({
      accessKeyId: "AKIA6FYXKEQZ34VSD47X",
      secretAccessKey: "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",
      region: "ap-southeast-1",
    });
    const params = {
      Bucket: "edglob2021",
      Key: foldername + "/" + fileName,
      ACL: "public-read",
      ContentType: file.type,
    };
    try {
      const data = await bucket.upload(params).promise();

      console.log("file upload ended");
      return {
        status: true,
        data: data,
      };
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
