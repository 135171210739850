import { Component, OnInit, Input } from "@angular/core";
import { AssetsObject, Course } from "src/app/core/models/course";
import { Router } from "@angular/router";
import { AuthService } from "src/app/shared/services/firebase/auth.service";

@Component({
  selector: "dashboard-courses-list",
  templateUrl: "./courses-list.component.html",
  styleUrls: ["./courses-list.component.css"],
})
export class DashboardCoursesListComponent implements OnInit {
  @Input("courses") courses: Course[];
  fallBackImageUrl = "assets/images/courses/course1.jpg";
  urlLength: number;
  assetsObj: AssetsObject[] = [];
  //this.urltype = this.name.length;
  //this.url = this.name.substr(this.urltype - 3, this.urltype);

  constructor(private router: Router, public authService: AuthService) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    // for (let i = 0; i < this.courses?.length; i++) {
    //   if (
    //     this.courses[i].type != "" &&
    //     this.courses[i].type != "mp4" &&
    //     this.courses[i].type != "mov" &&
    //     this.courses[i].type != "wmv" &&
    //     this.courses[i].type != "avi" &&
    //     this.courses[i].type != "flv" &&
    //     this.courses[i].type != "mpeg-2" &&
    //     this.courses[i].type != "mkv"
    //   ) {
    //     this.assetsObj[i].alt = "image";
    //     this.assetsObj[i].posterImage = this.courses[i].ImageUrl;
    //     this.assetsObj[i].title = "Course Images";
    //   } else {
    //     this.assetsObj[i].alt = "video";
    //     this.assetsObj[i].video = this.courses[i].ImageUrl;
    //     this.assetsObj[i].title = "Course Videos";
    //   }
    // }
    // this.courses?.forEach((ele, i) => {
    //   let urlLength = ele?.ImageUrl?.length;
    //   ele.type = ele?.ImageUrl?.substring(urlLength - 3, urlLength);
    // });
  }

  onEnroll(course: Course) {
    this.router.navigate(["/courses/" + course.ID]);

    // if (this.authService.username){
    //   this.router.navigate(['/courses/'+course.courseId]);
    // } else {
    //   this.router.navigate(['/auth/login']);
    // }
  }
}
