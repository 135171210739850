import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NavService, Menu } from "../../services/nav.service";
import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT } from "@angular/common";
import { CartService } from "src/app/core/services/cart.service";
import { UserService } from "../../services/user/authentication/user.service";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { CoursesService } from "../../services/courses.service";

var body = document.getElementsByTagName("body")[0];

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  courseImageUrl = "../../../../assets/images/courses/";
  public menuItems: Menu[];
  public items: Menu[];
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public openNav: boolean = false;
  public right_sidebar: boolean = false;
  public text: string;
  public elem;
  public isOpenMobile: boolean = false;
  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  coursesList = [];
  filteredCoursesList: Observable<any[]>;

  userinfo: any;
  subscription: Subscription;

  constructor(
    public navServices: NavService,
    @Inject(DOCUMENT) private document: any,
    private translate: TranslateService,
    public cartService: CartService,
    public userService: UserService,
    public courseService: CoursesService,
    public router: Router
  ) {
    translate.setDefaultLang("en");
    this.subscription = this.userService.missionAnnounced$.subscribe(
      (mission) => {
        this.currentUser.profileImage = mission;
      }
    );
  }

  control = new FormControl();

  ngOnInit() {
    this.elem = document.documentElement;

    this.navServices.items.subscribe((menuItems) => {
      this.items = menuItems;
    });
    this.getAllCourses();

    this.filteredCoursesList = this.control.valueChanges.pipe(
      startWith(""),
      map((state) => this._filter(state))
    );

    this.userinfo = JSON.parse(localStorage.getItem("edGlobCurrentUser"));
    if (this.userinfo != null) {
      this.userService.getUser(this.userinfo?.loggedUserId);
      this.cartService
        .getUserCartDetails(this.userinfo?.loggedUserId)
        .subscribe((x) => {});
      this.getUserData();
    }
  }

  currentUser: any;

  getUserData() {
    this.userService
      .getUserDetails(this.userinfo.loggedUserId)
      .subscribe((currentUser: any) => {
        if (currentUser) {
          this.currentUser = currentUser.UserList[0];
        }
      });
  }

  getAllCourses() {
    this.control.disable();
    this.courseService.getAllCourses().subscribe((response: any) => {
      this.coursesList = response.CourseList;
      this.control.enable();
    });
  }

  private _filter(value: string): string[] {
    if (value == null || value == undefined) return;
    const filterValue = value.toLowerCase();

    return this.coursesList.filter(
      (option) =>
        option.CourseTitle.toLowerCase().includes(filterValue) ||
        option.TeacherName.toLowerCase().includes(filterValue)
    );
  }

  ngOnDestroy() {
    this.removeFix();
    this.subscription.unsubscribe();
  }

  viewCourseDetail(title) {
    const id = title.ID;
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([`/courses/${id}`]));
  }

  right_side_bar() {
    this.right_sidebar = !this.right_sidebar;
    this.rightSidebarEvent.emit(this.right_sidebar);
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  public changeLanguage(lang) {
    this.translate.use(lang);
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return (this.menuItems = []);
    let items = [];
    term = term.toLowerCase();
    this.items.filter((menuItems) => {
      if (
        menuItems.title.toLowerCase().includes(term) &&
        menuItems.type === "link"
      ) {
        items.push(menuItems);
      }
      if (!menuItems.children) return false;
      menuItems.children.filter((subItems) => {
        if (
          subItems.title.toLowerCase().includes(term) &&
          subItems.type === "link"
        ) {
          subItems.icon = menuItems.icon;
          items.push(subItems);
        }
        if (!subItems.children) return false;
        subItems.children.filter((suSubItems) => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon;
            items.push(suSubItems);
          }
        });
      });
      this.checkSearchResultEmpty(items);
      this.menuItems = items;
    });
  }

  checkSearchResultEmpty(items) {
    if (!items.length) this.searchResultEmpty = true;
    else this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    body.classList.add("offcanvas");
  }

  removeFix() {
    this.searchResult = false;
    body.classList.remove("offcanvas");
    this.text = "";
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  logOut() {
    this.userService.setLogOut();
    sessionStorage.clear();
    localStorage.removeItem("edGlobCurrentUser");
    localStorage.removeItem("edGlobuserInfo");
    this.router.navigate(["/"]);
    this.navServices.collapseSidebar = false;
    this.collapseSidebar();
  }
}
