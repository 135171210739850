import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { S3uploadService } from "src/app/shared/services/s3upload.service";

@Component({
  selector: "app-image-uploader",
  templateUrl: "./image-uploader.component.html",
  styleUrls: ["./image-uploader.component.css"],
})
export class ImageUploaderComponent implements OnInit {
  croperData: any = null;
  spinner: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ImageUploaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private uploadService: S3uploadService
  ) {
    this.croperData = data;
  }

  imageChangedEvent: any = "";
  croppedImage: any = "";

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  async UploadFile() {
    this.spinner = true;
    var res = await this.uploadService.uploadCropedImage(
      this.croppedImage,
      "pictures"
    );
    if (this.croperData.imageType == "profile") {
      this.onNoClick(res);
    }
    // console.log(res);
  }

  imageLoaded(image: HTMLImageElement) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  ngOnInit(): void {}

  onNoClick(result): void {
    this.dialogRef.close(result);
  }
}
