import { Component, OnInit } from '@angular/core';
import { NavService } from '../shared/services/nav.service';
import { AuthService } from '../shared/services/firebase/auth.service';
import { UserService } from '../shared/services/user/authentication/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  router: string;
  constructor(
    public navService: NavService,
    public authService: AuthService,
    public userService: UserService
  ) { 
  }

  ngOnInit(): void {
    
  }

}
