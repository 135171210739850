import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

const URL = 'https://httpbin.org/post';

@Component({
  selector: 'app-advanced-components',
  templateUrl: './advanced-components.component.html',
  styleUrls: ['./advanced-components.component.css']
})
export class AdvancedComponentsComponent implements OnInit {

  columns = [
    { prop: 'id' },
    { name: 'First Name' },
    { name: 'Last Name' },
    { name: 'User Name' },
    { name: 'Role' },
    { name: 'Country' }
  ];

  users = [
    {
      id: 1,
      firstName: 'Alexander',
      lastName: 'Orton',
      userName: '@mdorton',
      role: 'admin',
      country: 'USA'
    },
    {
      id: 2,
      firstName: 'John',
      lastName: 'Doe',
      userName: '@johndeo',
      role: 'user',
      country: 'UK'
    },
    {
      id: 3,
      firstName: 'Randy Orton',
      lastName: 'the Bird',
      userName: '@twitter',
      role: 'admin',
      country: 'UK'
    }
  ]

  /* FOR FILE UPLOAD */
  public uploader: FileUploader = new FileUploader({
    url: URL,
    isHTML5: true 
  });

  public hasBaseDropZoneOver: boolean = false;

  constructor() { }

  ngOnInit(): void {
    
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

}
