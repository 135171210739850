import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.css"],
})
export class VideoComponent implements OnInit {
  constructor(private route: Router) {}
//add a target blank iframe not support webcam
  ngOnInit(): void {}
  ngAfterViewInit() {
     this.route.navigate(["https://atmvideochat.com/default.aspx"]);

      //  window.open("https://atmvideochat.com/default.aspx", "");
      window.open("https://jitsi.atmvideochat.com/", "");

   }
 }
