import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/shared/services/firebase/auth.service";
import {
  FormGroup,
  FormControl,
  NgForm,
  FormBuilder,
  FormArray,
  Validators,
} from "@angular/forms";
import { UserService } from "../../shared/services/user/authentication/user.service";
import { LoginUser } from "../../shared/services/user/login.model";
import { MatDialog } from "@angular/material/dialog";
import { UpdatePasswordComponent } from "./update-password/update-password.component";
import { S3uploadService } from "src/app/shared/services/s3upload.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ImageUploaderComponent } from "src/app/dashboard/components/image-uploader/image-uploader.component";
import { ImagePreviewerComponent } from "src/app/dashboard/components/image-previewer/image-previewer.component";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.css"],
})
export class EditProfileComponent implements OnInit {
  formGroup: FormGroup;
  user: LoginUser;

  url: any;
  loggedUserFullName: string;
  loggedUserType: string;
  loggedUserEmail: string;
  currentUser: any;
  userinfo: any;
  partciularAllValuesEmpty = false;

  countriesList = [];
  editProfileForm = new FormGroup({
    FirstName: new FormControl(""),
    LastName: new FormControl(""),
    fullName: new FormControl(""),
    UserAddress: new FormControl(""),
    UserQualifications: new FormControl(""),
    AboutMe: new FormControl(""),
    Nationality: new FormControl(""),
    Gender: new FormControl(""),
    DateOfBirth: new FormControl(""),
    Email: new FormControl("", Validators.email),
    Qualification: new FormControl(""),
    University: new FormControl(""),
    Country: new FormControl(""),
    /* FieldStudy: new FormControl(''),
          University: new FormControl(''),
          Country: new FormControl(''),
          GraduateYear: new FormControl('')*/
  });

  editUserLoginForm = new FormGroup({
    ID: new FormControl("", [Validators.required]),
    UserTypeID: new FormControl(""),
    LoginID: new FormControl(""),
    Password: new FormControl(""),
    FirstName: new FormControl("", [Validators.required]),
    LastName: new FormControl("", [Validators.required]),
    Email: new FormControl("", [Validators.required, Validators.email]),
  });

  editAddressForm = new FormGroup({
    BlockNo: new FormControl(""),
    RoomNo: new FormControl(""),
    Street: new FormControl(""),
    ZipCode: new FormControl(""),
    City: new FormControl(""),
    Country: new FormControl(""),
  });

  particularsForm = new FormGroup({
    AboutMe: new FormControl("", [Validators.required]),
    Gender: new FormControl("", [Validators.required]),
    DateOfBirth: new FormControl("", [Validators.required]),
    Race: new FormControl("", [Validators.required]),
    Nationality: new FormControl("", [Validators.required]),
  });

  specificRequestForm = new FormGroup({
    ID: new FormControl(""),
    UserId: new FormControl(""),
    specificRequest: new FormControl("", [Validators.required]),
    Keyword: new FormControl(""),
  });

  qualificationsDynamicForm: FormGroup;
  addressDynamicForm: FormGroup;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private uploadService: S3uploadService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.userService.getCountriesList().subscribe((x) => {
      this.countriesList = x;
    });
    this.qualificationsDynamicForm = this.formBuilder.group({
      qualifications: new FormArray([]),
    });

    this.addressDynamicForm = this.formBuilder.group({
      addresses: new FormArray([]),
    });

    this.formGroup = new FormGroup({
      username: new FormControl(this.authService.username),
      email: new FormControl("test@test.com"),
    });

    this.getName();

    this.userinfo = JSON.parse(localStorage.getItem("edGlobCurrentUser"));
    this.userService.getUser(this.userinfo.loggedUserId);
    this.getUserData();
  }

  get qualificationsF() {
    return this.qualificationsDynamicForm.controls;
  }
  get qualificationsFArray() {
    return this.qualificationsF.qualifications as FormArray;
  }

  get addressF() {
    return this.addressDynamicForm.controls;
  }
  get addressFArray() {
    return this.addressF.addresses as FormArray;
  }

  get f_editUserLoginForm() {
    return this.editUserLoginForm.controls;
  }

  get f_particularsForm() {
    return this.particularsForm.controls;
  }

  get f_specificRequestForm() {
    return this.specificRequestForm.controls;
  }

  onSubmit() {
    if (!this.qualificationsFArray.valid) {
      this._snackBar.open("Invalid values in form successfully", "", {
        duration: 3000,
        panelClass: ["red-snackbar"],
      });
      this.qualificationsFArray.markAllAsTouched();
      return;
    }

    if (this.qualificationsDynamicForm.value.qualifications) {
      let len = this.qualificationsDynamicForm.value.qualifications.length;

      this.qualificationsDynamicForm.value.qualifications.forEach((item) => {
        this.userService
          .updateUserQualificataions(item, this.currentUser.ID)
          .subscribe((response) => {
            if (response) {
              len = len - 1;
              if (len == 0) {
                this.getUserData();
                this._snackBar.open("Updated successfully", "", {
                  duration: 3000,
                  panelClass: ["green-snackbar"],
                });
                this.qualificationsFArray.markAsUntouched();
              }
            }
          });
      });
    }
  }

  onAddressSubmit() {
    if (!this.addressFArray.valid) {
      this._snackBar.open("Invalid values in form successfully", "", {
        duration: 3000,
        panelClass: ["red-snackbar"],
      });
      this.addressFArray.markAllAsTouched();
      return;
    }
    if (this.addressDynamicForm.value.addresses) {
      let len = this.addressDynamicForm.value.addresses.length;
      this.addressDynamicForm.value.addresses.forEach((item) => {
        this.userService
          .updateUserAddress(item, this.currentUser.ID)
          .subscribe((response) => {
            if (response) {
              len = len - 1;
              if (len == 0) {
                this._snackBar.open("Updated Successfully", "", {
                  duration: 3000,
                  panelClass: ["green-snackbar"],
                });
                this.addressFArray.markAsUntouched();
                this.getUserData();
              }
            }
          });
      });
    }
  }

  insertQualificationForm(item: any) {
    this.qualificationsFArray.push(
      this.formBuilder.group({
        Qualification: [item.Qualification, [Validators.required]],
        FieldStudy: [item.FieldStudy, [Validators.required]],
        University: [item.University, [Validators.required]],
        Country: [item.Country, [Validators.required]],
        GraduateYear: [item.GraduateYear, [Validators.required]],
        QualID: [item.QualID, [Validators.required]],
      })
    );
  }

  updateQualificationForm(toAdd: boolean, index: number = 0) {
    if (toAdd) {
      this.qualificationsFArray.push(
        this.formBuilder.group({
          Qualification: ["", [Validators.required]],
          FieldStudy: ["", [Validators.required]],
          University: ["", [Validators.required]],
          Country: ["", [Validators.required]],
          GraduateYear: ["", [Validators.required]],
          QualID: [0, [Validators.required]],
        })
      );
    } else {
      if (
        this.currentUser.UserQualifications.find(
          ({ QualID }) =>
            QualID === this.qualificationsFArray.value[index].QualID
        )
      ) {
        let check = confirm("Are you sure you want to delete this record?");
        if (check) {
          this.userService
            .deleteUserQualificataions(
              this.qualificationsFArray.value[index].QualID
            )
            .subscribe((x) => {
              this.qualificationsFArray.removeAt(index);
            });
        }
      } else {
        this.qualificationsFArray.removeAt(index);
      }
    }
  }

  insertAddressForm(item: any) {
    this.addressFArray.push(
      this.formBuilder.group({
        BlockNo: [item.BlockNo],
        RoomNo: [item.RoomNo],
        Street: [item.Street, [Validators.required]],
        ZipCode: [item.ZipCode, [Validators.required]],
        City: [item.City, [Validators.required]],
        Country: [item.Country, [Validators.required]],
        AddrID: [item.AddrID],
      })
    );
  }

  updateAddressForm(toAdd: boolean, index: number = 0) {
    if (toAdd) {
      this.addressFArray.push(
        this.formBuilder.group({
          BlockNo: [""],
          RoomNo: [""],
          Street: ["", [Validators.required]],
          ZipCode: ["", [Validators.required]],
          City: ["", [Validators.required]],
          Country: ["", [Validators.required]],
          AddrID: [0],
        })
      );
    } else {
      if (
        this.currentUser.UserAddress.find(
          ({ AddrID }) => AddrID === this.addressFArray.value[index].AddrID
        )
      ) {
        let check = confirm("Are you sure you want to delete this record?");
        if (check) {
          this.userService
            .deleteUserAddress(this.addressFArray.value[index].AddrID)
            .subscribe((x) => {
              this.addressFArray.removeAt(index);
            });
        }
      } else {
        this.addressFArray.removeAt(index);
      }
    }
  }

  showPictureUploadbuttons = false;

  async selectFile(event) {
    debugger;
    var filename =
      (new Date().getTime() / 1000).toFixed(0) + "_" + this.currentUser.ID;
    var res = await this.uploadService.uploadFile(
      event.target.files[0],
      "pictures",
      filename
    );
    console.log(res);
    this.userService
      .updateProfilePicture(this.currentUser.ID, res.data.Location, "somename")
      .subscribe((x) => {
        if (x == true) {
          this.userService.announceMission(res.data.Location);
          this.getUserData();
        }
      });
  }

  removePicture() {
    this.userService
      .updateProfilePicture(this.currentUser.ID, "_", "somename")
      .subscribe((x) => {
        if (x == true) {
          this._snackBar.open("Image removed", "", {
            duration: 3000,
            panelClass: ["green-snackbar"],
          });
          this.userService.announceMission("empty");
          this.getUserData();
        } else {
          this._snackBar.open("Some error has occured", "", {
            duration: 3000,
            panelClass: ["red-snackbar"],
          });
        }
      });
  }

  getUserData() {
    this.showPictureUploadbuttons = false;
    this.userService
      .getUserDetails(this.userinfo.loggedUserId)
      .subscribe((currentUser: any) => {
        if (currentUser) {
          this.currentUser = currentUser.UserList[0];
          if (
            this.currentUser.DateOfBirth != null &&
            this.currentUser.DateOfBirth != ""
          )
            this.currentUser.DateOfBirth = this.currentUser.DateOfBirth.split(
              "-"
            )
              .reverse()
              .join("-");

          this.editProfileForm.setValue(
            /**/
            {
              FirstName: this.currentUser.FirstName || "",
              LastName: this.currentUser.LastName || "",
              fullName: this.currentUser.Full_Name || "",
              UserAddress: this.currentUser.UserAddress || "",
              UserQualifications: this.currentUser.UserQualifications || "",
              AboutMe: this.currentUser.AboutMe || "",
              Nationality: this.currentUser.Nationality || "",
              Gender: this.currentUser.Gender || "",
              DateOfBirth: this.currentUser.DateOfBirth || "",
              Qualification: this.currentUser.Qualification || "",
              University: this.currentUser.University || "",
              Country: this.currentUser.Country,
              Email: this.currentUser.Email || "",
            }
          );
          this.editUserLoginForm.setValue({
            ID: this.currentUser.ID,
            FirstName: this.currentUser.FirstName || "",
            LastName: this.currentUser.LastName || "",
            Email: this.currentUser.Email || "",
            UserTypeID: this.currentUser.UserTypeID || "",
            LoginID: this.currentUser.LoginID || "",
            Password: this.currentUser.Password || "",
          });

          if (
            this.currentUser.UserQualifications &&
            this.currentUser.UserQualifications.length > 0
          ) {
            this.qualificationsDynamicForm = this.formBuilder.group({
              qualifications: new FormArray([]),
            });

            for (
              let i = 0;
              i < this.currentUser.UserQualifications.length;
              i++
            ) {
              this.insertQualificationForm(
                this.currentUser.UserQualifications[i]
              );
            }
          } else {
            this.updateQualificationForm(true);
          }

          if (
            this.currentUser.UserAddress &&
            this.currentUser.UserAddress.length > 0
          ) {
            this.addressDynamicForm = this.formBuilder.group({
              addresses: new FormArray([]),
            });

            for (let i = 0; i < this.currentUser.UserAddress.length; i++) {
              this.insertAddressForm(this.currentUser.UserAddress[i]);
            }
          } else {
            this.updateAddressForm(true);
          }

          this.particularsForm.setValue({
            AboutMe: this.currentUser.AboutMe || null,
            Gender: this.currentUser.Gender || null,
            DateOfBirth: this.currentUser.DateOfBirth || null,
            Race: this.currentUser.Race || null,
            Nationality: this.currentUser.Nationality || null,
          });

          this.specificRequestForm.setValue({
            ID: this.currentUser.RequestId || null,
            UserId: this.currentUser.ID || null,
            specificRequest: this.currentUser.SpecificRequest || null,
            Keyword: null,
          });
          this.checkALLEmpty();
        }
      });
  }

  getName() {
    this.loggedUserFullName = sessionStorage.getItem("loggedUser");
    this.loggedUserType = sessionStorage.getItem("loggedUserType");
    this.loggedUserEmail = sessionStorage.getItem("loggedUserEmail");
  }

  readUrl(event: any) {
    if (event.target.files.length === 0) {
      return;
    }
    // Image upload validation
    const mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    };
  }

  saveAddress(form) {
    this.userService
      .updateUserAddress(form.value, this.currentUser.ID)
      .subscribe((response) => {
        if (response) {
          this._snackBar.open("Updated successfully", "", {
            duration: 3000,
            panelClass: ["green-snackbar"],
          });
        }
      });
  }

  saveSpecificRequest() {
    if (!this.specificRequestForm.valid) {
      this.specificRequestForm.markAllAsTouched();
      this._snackBar.open("Please enter your request", "", {
        duration: 3000,
        panelClass: ["red-snackbar"],
      });
      return;
    }

    this.userService
      .addUserSpecificRequest(this.specificRequestForm.value)
      .subscribe((resp) => {
        if (resp != false) {
          this._snackBar.open("Updated successfully", "", {
            duration: 3000,
            panelClass: ["green-snackbar"],
          });

          this.getUserData();
          this.specificRequestForm.markAsUntouched();
        }
      });
  }

  deleteSpecificRequest() {
    this.userService
      .deleteUserSpecificRequest(this.specificRequestForm.value.ID)
      .subscribe((resp) => {
        if (resp != false) {
          this._snackBar.open("Deleted successfully", "", {
            duration: 3000,
            panelClass: ["green-snackbar"],
          });
          this.getUserData();
        }
      });
  }

  updateSpecificRequest() {
    if (!this.specificRequestForm.valid) {
      this.specificRequestForm.markAllAsTouched();
      this._snackBar.open("Please enter your request", "", {
        duration: 3000,
        panelClass: ["red-snackbar"],
      });
      return;
    }
    this.userService
      .updateUserSpecificRequest(this.specificRequestForm.value)
      .subscribe((resp) => {
        if (resp != false) {
          this._snackBar.open("Updated Successfully", "", {
            duration: 3000,
            panelClass: ["green-snackbar"],
          });
          this.specificRequestForm.markAsUntouched();
          this.getUserData();
        }
      });
  }

  checkALLEmpty() {
    if (
      !this.particularsForm.controls["AboutMe"].value &&
      !this.particularsForm.controls["Gender"].value &&
      !this.particularsForm.controls["DateOfBirth"].value &&
      !this.particularsForm.controls["Race"].value &&
      !this.particularsForm.controls["Nationality"].value
    ) {
      this.partciularAllValuesEmpty = true;
    } else {
      this.partciularAllValuesEmpty = false;
    }
  }

  saveParticular(form) {
    if (!form.valid) {
      form.markAllAsTouched();
      this._snackBar.open("Invalid form values", "", {
        duration: 3000,
        panelClass: ["red-snackbar"],
      });
      return;
    }
    const activeButton = document.activeElement.id;
    let urlName = "";
    if (activeButton === "saveParticular") {
      urlName = "Save";
    }
    if (activeButton === "updateParticular") {
      urlName = "Update";
    }
    this.userService
      .updateUserParticulars(urlName, form.value, this.currentUser.ID)
      .subscribe((response) => {
        if (response) {
          this.userService.getUser(this.currentUser.ID);
          this.checkALLEmpty();
          this._snackBar.open("Updated successfully", "", {
            duration: 3000,
            panelClass: ["green-snackbar"],
          });
          form.markAsUntouched();
        }
      });
  }

  updateUserLogin() {
    if (!this.editUserLoginForm.valid) {
      this.editUserLoginForm.markAllAsTouched();

      this._snackBar.open("Invalid values in form", "", {
        duration: 3000,
        panelClass: ["red-snackbar"],
      });
      return;
    }
    this.userService
      .updateUserLogin(this.editUserLoginForm.value)
      .subscribe((x) => {
        if (x) {
          this._snackBar.open("Updated Successfully", "", {
            duration: 3000,
            panelClass: ["green-snackbar"],
          });
          this.editUserLoginForm.markAsUntouched();
          this.getUserData();
        }
      });
  }

  openPasswordDialog() {
    const dialogRef = this.dialog.open(UpdatePasswordComponent, {
      width: "400px",
      height: "370px",
      data: this.editUserLoginForm.value,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.getUserData();
    });
  }

  openImageDialog() {
    const dialogRef = this.dialog.open(ImageUploaderComponent, {
      width: "90vw",
      height: "90vh",
      data: {
        ratio: "1 / 1",
        imageType: "profile",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.userService
        .updateProfilePicture(
          this.currentUser.ID,
          result.data.Location,
          "somename"
        )
        .subscribe((x) => {
          if (x == true) {
            this.userService.announceMission(result.data.Location);
            this.getUserData();
            this._snackBar.open("Updated Successfully", "", {
              duration: 3000,
              panelClass: ["green-snackbar"],
            });
          } else {
            this._snackBar.open("Some error has occured", "", {
              duration: 3000,
              panelClass: ["red-snackbar"],
            });
          }
        });
    });
  }

  openImagePreviewer(url) {
    if (url == "assets/images/noimage.png") {
      return;
    }
    const dialogRef = this.dialog.open(ImagePreviewerComponent, {
      data: url,
    });
  }
}
