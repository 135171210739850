import { Component, OnInit } from "@angular/core";
import { CoursesService } from "src/app/shared/services/courses.service";
import { Router } from "@angular/router";
import { UserService } from "../../../shared/services/user/authentication/user.service";
import { FormControl } from "@angular/forms";
import { countries } from "../../Classes/teacherClass";

@Component({
  selector: "app-students-list",
  templateUrl: "./students-list.component.html",
  styleUrls: ["./students-list.component.css"],
})
export class StudentsListComponent implements OnInit {
  textShowLength = 20;
  students: any[] = [];
  studentsDisplay = [];
  control = new FormControl();
  countriesList: countries[] = [];
  settings = {};
  selectedItems = [];
  country: any = '';
  specific: any = '';
  userCountryList: any[] = []

  constructor(
    private userService: UserService,
    private coursesService: CoursesService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.teachers = this.coursesService.getAllInstructor();
    this.getStudentList();
    //this.getCountries();

    this.settings = {
      enableSearchFilter: true,
      addNewItemOnFilter: false, singleSelection: true, text: "Select Country"
    };
  }

  onItemSelect(item: any) {
    let param = { target: { value: item.id } }
    this.searchTerm(param, 'country')
  }
  OnItemDeSelect(item: any) {
    let param = { target: { value: '' } }
    this.searchTerm(param, 'country')
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    let param = { target: { value: '' } }
    this.searchTerm(param, 'country')
  }

  onViewCourses(id: number) {
    console.log("clicked studets", id);
    this.router.navigate(["/students/" + id]);
  }

  getStudentList() {
    this.coursesService.getAllStudents().subscribe(
      (response) => {
        this.students = response.UserList;
        let list = response.UserList.filter(x => x.Country != null)
        this.userCountryList = list.filter((a, i) => list.findIndex((s) => a.Country === s.Country) === i)

        this.getCountries();
        this.studentsDisplay = this.students;
      },
      (err) => { }
    );
  }

  searchTerm(eve: any, type) {
    var term = eve.target.value;

    console.log(term)
    if (type === "country") this.country = term;
    if (type === "specific") this.specific = term;
    // if (term == "") this.getStudentList();

    // console.log(this.country + ' -- '+this.specific)
    this.userService
      .getStudentFilteredResults(this.country, this.specific)
      .subscribe((response) => {
        if (response) {
          this.studentsDisplay = [];
          // if (response[0].length > 0) this.studentsDisplay = response[0];
          if (response.length > 0) {
            this.studentsDisplay = response
          }
        }
      });


  }

  getCountries() {
    this.countriesList = []
    this.userService.getCountries().subscribe((res) => {
      this.userCountryList.forEach(element => {
        let obj = res.UserDropStringList.find(x => x.Id == element.Country)
        this.countriesList.push({ id: obj.Id, itemName: obj.Value })
      });
    });
  }
}
