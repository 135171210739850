import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { CourseService } from "src/app/shared/services/course/course.service";
import { FormGroup, FormBuilder, FormArray, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { EditTopicComponent } from "./edit-topic/edit-topic.component";
import { DomSanitizer } from "@angular/platform-browser";
import { EditLessonComponent } from "./edit-lesson/edit-lesson.component";
import { S3uploadService } from "src/app/shared/services/s3upload.service";
import { ImagePreviewerComponent } from "../../components/image-previewer/image-previewer.component";
import { DocumentPreviewerComponent } from "../../components/document-previewer/document-previewer.component";

@Component({
  selector: "app-edit-course",
  templateUrl: "./edit-course.component.html",
  styleUrls: ["./edit-course.component.css"],
})
export class EditCourseComponent implements OnInit {
  user: any;
  courseID: number;
  courseForm: FormGroup;
  topicForm: FormGroup;
  courseDetails;
  courseTopics;
  topicCollaspeList = [];
  imgDisplay;
  thumbNailDisplay;
  videoDisplay;
  urlImg: any;
  urlThumbNail;
  urlVideo;
  format;
  imgFile: any;
  thmbFile: any;
  videoFile: any;
  @ViewChild("videoInp")
  myvideoInp: ElementRef;

  @ViewChild("thumbInp")
  myThumbInp: ElementRef;

  @ViewChild("imgInp")
  myImgInp: ElementRef;

  constructor(
    private courseService: CourseService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private uploadService: S3uploadService
  ) {
    this.user = JSON.parse(localStorage.getItem("edGlobCurrentUser"));
    this.resetTopicForm();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.courseID = params.id;
      this.getCourseDetail();
    });
  }

  getCourseDetail() {
    this.courseService.getCourseDetails(this.courseID).subscribe((details) => {
      this.courseDetails = details.CourseDetailList[0].CourseDetail;
      this.courseTopics = details.CourseDetailList[0].CourseTopicList;
      this.imgDisplay = this.courseDetails.ImageUrl;
      this.urlImg = this.courseDetails.ImageUrl;
      this.topicCollaspeList = [];

      this.courseTopics.forEach((item) => {
        this.topicCollaspeList.push(false);
        item.CourseLessons.forEach((element) => {
          element["exp_btn_text"] = "Hide";
          element["lessonContentunsafe"] = element.lessonContent;
          element.lessonContent = this.sanitizer.bypassSecurityTrustHtml(
            element.lessonContent
          );
        });
      });

      this.resetForm(details);
    });
  }

  resetTopicForm() {
    this.topicForm = this.formBuilder.group({
      ID: [0],
      CourseId: [0],
      CourseTopicDescription: ["", Validators.required],
      CourseTopicTitle: ["", Validators.required],
      TopicTime: [Validators.required],
    });
  }

  resetForm(obj: any) {
    // const subject_filling = this.getRandomString(6);
    this.courseForm = this.formBuilder.group({
      ID: [this.courseDetails.ID],
      GradeId: [this.courseDetails.ID ? 1 : this.courseDetails.ID],
      CourseReferenceNo: [this.courseDetails.CourseReferenceNo],
      CourseTitle: [this.courseDetails.CourseTitle, Validators.required],
      whatULearn: [this.courseDetails.whatULearn, Validators.required],
      CourseDescription: [
        this.courseDetails.CourseDescription,
        Validators.required,
      ],
      TeacherId: [this.courseDetails.TeacherId],
      SubjectId: [this.courseDetails.SubjectId, Validators.required],
      Fee: [this.courseDetails.Fee],
      NoOfLessons: [this.courseDetails.NoOfLessons, Validators.required],
      StartDate: [this.getDateInFormat(new Date(this.courseDetails.StartDate))],
      EndDate: [this.getDateInFormat(new Date(this.courseDetails.EndDate))],
      IsActive: [this.courseDetails.IsActive],
      NoOfSeat: [this.courseDetails.NoOfSeat, Validators.required],
      SchoolRefNo: [this.courseDetails.SchoolRefNo],
      NoOfReview: [this.courseDetails.NoOfReview],
      AverageRating: [this.courseDetails.AverageRating],
      Enroll: [this.courseDetails.Enroll],
      // topics: new FormArray([]),
    });
  }

  get courseF() {
    return this.courseForm.controls;
  }
  get courseFArray() {
    return this.courseF.qualifications as FormArray;
  }

  private getDateInFormat(date: Date) {
    var today = date;
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  }

  OnSubmit() {
    if (this.courseForm.invalid) {
      alert("Invalid values in form");
      return;
    }
    let courseId = this.courseForm.value["ID"];
    this.courseService.updateCourse(this.courseForm.value).subscribe(
      (data: any) => {
        if (data != 0 && data != false) {
          this.toastr.success("Record inserted", "Success");
          if (
            this.urlImg != "" &&
            this.urlImg != null &&
            this.urlImg != undefined
          ) {
            this.uploadUpdate(this.imgDisplay, this.imgFile, courseId);
            this.urlImg = null;
            this.myImgInp.nativeElement.value = "";
          }
          if (
            this.urlThumbNail != "" &&
            this.urlThumbNail != null &&
            this.urlThumbNail != undefined
          ) {
            this.uploadUpdate(this.thumbNailDisplay, this.thmbFile, data);
            this.urlThumbNail = null;
            this.myThumbInp.nativeElement.value = "";
          }
          if (
            this.urlVideo != "" &&
            this.urlVideo != null &&
            this.urlVideo != undefined
          ) {
            this.uploadUpdate(this.videoDisplay, this.videoFile, data);
            this.urlVideo = null;
            this.myvideoInp.nativeElement.value = "";
          }
        } else {
          this.toastr.error("Please try again", "Error");
        }
      },
      (err) => {
        this.toastr.error("Please try again", "Error");
      }
    );
  }

  OnTopicSubmit() {
    this.topicForm.patchValue({ CourseId: this.courseDetails.ID });
    if (this.topicForm.invalid) {
      alert("Invaid values in form");
      return;
    }
    this.courseService
      .createCourseTopic(this.topicForm.value)
      .subscribe((x) => {
        console.log(x);
        if (x != 0 && x != false) {
          this.getCourseDetail();
          this.resetTopicForm();
        }
      });
  }

  deleteTopic(id) {
    if (!confirm("Are you sure you want to delete this topic?")) return;
    this.courseService.deleteCourseTopic(id).subscribe((x) => {
      if (x) this.getCourseDetail();
    });
  }

  editTopic(topic) {
    console.log(topic);

    const dialogRef = this.dialog.open(EditTopicComponent, {
      width: "65vw",
      height: "70vh",
      data: topic,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.getCourseDetail();
    });
  }

  deleteLesson(id) {
    if (!confirm("Are you sure you want to delete this lesson?")) return;
    this.courseService.deleteLeson(id).subscribe((x) => {
      if (x) this.getCourseDetail();
    });
  }

  editLesson(lesson) {
    const dialogRef = this.dialog.open(EditLessonComponent, {
      width: "80vw",
      height: "80vh",
      data: lesson,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.getCourseDetail();
    });
  }

  openDialog(index: number): void {
    const dialogRef = this.dialog.open(CreateLessonDialog, {
      width: "80vw",
      height: "80vh",
      data: this.courseTopics[index],
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.getCourseDetail();
    });
  }

  openAtachment(url) {
    let extension = url.split(".").pop().toLowerCase();
    if (extension == "pdf" || extension == "doc" || extension == "docx") {
      this.openDocumentPreviewer(url);
    } else if (
      extension == "png" ||
      extension == "jpg" ||
      extension == "gif" ||
      extension == "jpeg"
    ) {
      this.openImagePreviewer(url);
    } else {
      window.open(url);
    }
  }

  openImagePreviewer(url) {
    if (url == "assets/images/noimage.png") {
      return;
    }
    const dialogRef = this.dialog.open(ImagePreviewerComponent, {
      data: url,
    });
  }

  openDocumentPreviewer(url) {
    const dialogRef = this.dialog.open(DocumentPreviewerComponent, {
      data: url,
      width: "90vw",
      height: "90vh",
    });
  }

  getRandomString(length) {
    var randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

  deleteFile(type) {
    if (type == "image") {
      this.urlImg = null;
      this.myImgInp.nativeElement.value = "";
    } else if (type == "video") {
      this.urlVideo = null;
      this.myvideoInp.nativeElement.value = "";
    } else if (type == "thumbNail") {
      this.urlThumbNail = null;
      this.myThumbInp.nativeElement.value = "";
    } else {
      return;
    }
  }

  uploadUpdate(url, file, courseId) {
    let fileName = file.name.split(".");
    let result = this.uploadService.uploadCropedImage(
      url,
      "courses",
      fileName[0]
    );
    result.then((res) => {
      console.log(res);
      if (res.data.Location) {
        this.courseService
          .saveCourseAssets(res.data.Location, courseId)
          .subscribe(
            (res) => {
              console.log(res);
            },
            (err) => {
              console.log();
            }
          );
      }
    });
  }

  onSelectFile(event, type) {
    const file = event.target.files && event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf("image") > -1 && type == "image") {
        this.format = "image";
        this.imgFile = file;
        reader.onload = (event) => {
          this.imgDisplay = (<FileReader>event.target).result;
        };
        this.urlImg = this.myImgInp.nativeElement.value.split("\\")[2];
      } else if (file.type.indexOf("video") > -1 && type == "video") {
        this.format = "video";
        this.videoFile = file;
        reader.onload = (event) => {
          this.videoDisplay = (<FileReader>event.target).result;
        };
        this.urlVideo = this.myvideoInp.nativeElement.value.split("\\")[2];
      } else if (file.type.indexOf("image") > -1 && type == "thumbNail") {
        this.format = "image";
        this.thmbFile = file;
        reader.onload = (event) => {
          this.thumbNailDisplay = (<FileReader>event.target).result;
        };
        this.urlThumbNail = this.myThumbInp.nativeElement.value.split("\\")[2];
      } else {
        return;
      }
    }
  }
}

@Component({
  selector: "app-create-lesson-dialog",
  templateUrl: "create-lesson-dialog.html",
})
export class CreateLessonDialog {
  topic: any;

  constructor(
    public dialogRef: MatDialogRef<CreateLessonDialog>,
    private formBuilder: FormBuilder,
    public courseService: CourseService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private uploadService: S3uploadService
  ) {
    this.topic = data;
    this.resetLessonForm();
  }

  lessonForm: FormGroup;
  lessonContent = "";

  resetLessonForm() {
    this.lessonForm = this.formBuilder.group({
      ID: [0],
      CourseId: [this.topic.CourseId],
      CourseTopicId: [this.topic.TopicId],
      Title: ["", Validators.required],
      Description: ["", Validators.required],
      lessonContent: [""],
      UTCStartTime: [""],
      UTCEndTime: [""],
      ClassRoomNo: [""],
      ClassRoomLink: [""],
      timeRequire: [Validators.required],
      FeatureImg: [""],
      uploadAtachment: [""],
    });
  }

  selectedLessonThumbnail: File;
  selectedLessonAttachements: FileList;

  selectFile(event, itemType) {
    if (itemType == "thumbnail")
      this.selectedLessonThumbnail = event.target.files[0];
    if (itemType == "attachment")
      this.selectedLessonAttachements = event.target.files;
  }

  async onSubmit() {
    if (this.lessonContent == "" || this.lessonContent == null) {
      alert("Please add lesson content");
      return;
    }

    this.lessonForm.patchValue({
      lessonContent: this.lessonContent,
    });

    if (this.lessonForm.invalid) {
      alert("Invalid values in form");
      return;
    }

    let thumnailData;
    if (this.selectedLessonThumbnail) {
      var resp = await this.uploadService.uploadFile(
        this.selectedLessonThumbnail,
        "courses"
      );
      thumnailData = resp.data.Location;
      this.selectedLessonThumbnail = null;
    }

    let courseMaterialData = "";
    if (this.selectedLessonAttachements) {
      for (let i = 0; i < this.selectedLessonAttachements.length; i++) {
        var temp = await this.uploadService.uploadFile(
          this.selectedLessonAttachements[i],
          "courses"
        );
        courseMaterialData += temp.data.Location + ",";
      }
      this.selectedLessonAttachements = null;

      courseMaterialData = courseMaterialData.substring(
        0,
        courseMaterialData.length - 1
      );
    }

    this.lessonForm.patchValue({
      FeatureImg: thumnailData,
    });

    this.lessonForm.patchValue({
      uploadAtachment: courseMaterialData,
    });

    this.courseService.createLesson(this.lessonForm.value).subscribe((x) => {
      console.log(x, " resp");
      if (x != 0 && x != false) this.onNoClick(true);
    });
  }

  onNoClick(result): void {
    this.dialogRef.close(result);
  }
}
