import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import "rxjs/add/operator/filter";
import {CartService} from "src/app/core/services/cart.service";

@Component({
    selector: "app-payment-status",
    templateUrl: "./payment-status.component.html",
    styleUrls: ["./payment-status.component.css"],
})
export class PaymentStatus implements OnInit {
    private params;
    paymentStatus = "";
    paymentAmount = "0.00";

    constructor(
        private route: ActivatedRoute,
        private cartService: CartService
    ) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.params = params;

            this.paymentStatus = this.params.status;

            if (this.paymentStatus === 'success') {
                this.getUserCartDetails(JSON.parse(localStorage.getItem("edGlobuserInfo")).ID);
                this.paymentAmount = this.params.amount;
            }

            /* console.log('params',params);
             this.getUserCartDetails(this.params.user);

             if (this.params.amount) this.paymentAmount = this.params.amount;
             console.log(this.params);*/
        });
    }

    getUserCartDetails(userid) {
        this.cartService.getUserCartDetails(userid).subscribe((details) => {
            let cartItems = details.CartInfoList[0].CartLineItems.map((items) => {
                return {
                    itemId: items.Id,
                    cartId: items.cartId,
                };
            });
            this.deleteItemsFromCart(cartItems);
        });
    }

    deleteItemsFromCart(cartItems) {
        const length = cartItems.length;
        let counter = 0;

        cartItems.forEach((element) => {
            this.cartService
                .removeCourse(element.cartId, element.itemId)
                .subscribe((result) => {
                    counter++;
                    if (counter == length) {
                        this.paymentStatus = this.params.status;
                    }
                });
        });
    }
}
