import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {LoginComponent} from "./auth/login/login.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {AdminGuard} from "./shared/guard/admin.guard";
import {HomeComponent} from "./dashboard/pages/home/home.component";
import {CourseOverviewComponent} from "./dashboard/pages/course-overview/course-overview.component";
import {TeachersListComponent} from "./dashboard/pages/teachers-list/teachers-list.component";
import {StudentsListComponent} from "./dashboard/pages/students/students-list.component";
import {HybridCoursesComponent} from "./dashboard/pages/teacher-courses/hybrid-courses.component";
import {TeacherCourseListComponent} from "./dashboard/pages/teacher-course-list/teacher-course-list.component";
import {AllCoursesComponent} from "./dashboard/pages/all-courses/all-courses.component";
import {CategoryCoursesComponent} from "./dashboard/pages/category-courses/category-courses.component";
import {NewsComponent} from "./dashboard/pages/news/news.component";
import {GalleryComponent} from "./dashboard/pages/gallery/gallery.component";
import {AboutUsComponent} from "./dashboard/pages/about-us/about-us.component";
import {ClassesComponent} from "./dashboard/pages/classes/classes.component";
import {ShopComponent} from "./dashboard/pages/shop/shop.component";
import {DashboardStatsComponent} from "./dashboard/pages/dashboard-stats/dashboard-stats.component";
import {EditProfileComponent} from "./auth/edit-profile/edit-profile.component";
import {DashboardComponentsComponent} from "./dashboard/pages/dashboard-components/dashboard-components.component";
import {AdvancedComponentsComponent} from "./dashboard/pages/advanced-components/advanced-components.component";
import {ChatComponent} from "./dashboard/pages/chat/chat.component";
import {CartComponent} from "./dashboard/pages/cart/cart.component";
import {SocialAppComponent} from "./dashboard/pages/social-app/social-app.component";
import {CreateCourseComponent} from "./dashboard/pages/create-course/create-course.component";
import {EditCourseComponent} from "./dashboard/pages/edit-course/edit-course.component";
import {SignupPortalComponent} from "./auth/signup/signup-portal/signup-portal.component";
import {SignupStudentComponent} from "./auth/signup/signup-student/signup-student.component";
import {SignupTeacherComponent} from "./auth/signup/signup-teacher/signup-teacher.component";
import {VideoComponent} from "./dashboard/pages/video/video.component";
import {LiveComponent} from "./dashboard/pages/live/live.component";
import {PaymentStatus} from "./dashboard/pages/payment-status/payment-status.component";
import {BroadCastComponent} from "./dashboard/pages/broad-cast/broad-cast.component";

const routes: Routes = [
    {
        path: "",
        component: DashboardComponent,
        children: [
            {path: "", component: HomeComponent},
            {
                path: "edit-profile",
                component: EditProfileComponent,
                canActivate: [AdminGuard],
            },
            {path: "chat", component: ChatComponent, canActivate: [AdminGuard]},
            {
                path: "dashboard",
                component: DashboardStatsComponent,
                canActivate: [AdminGuard],
            },
            {
                path: "components",
                component: DashboardComponentsComponent,
                canActivate: [AdminGuard],
            },
            {
                path: "components-advanced",
                component: AdvancedComponentsComponent,
                canActivate: [AdminGuard],
            },
            {path: "courses", component: AllCoursesComponent},
            {path: "teachers-courses", component: TeacherCourseListComponent},
            {path: "students", component: StudentsListComponent},
            {
                path: "students/:id",
                component: HybridCoursesComponent,
                canActivate: [AdminGuard],
            },
            {
                path: "teachers/:id",
                component: HybridCoursesComponent,
                canActivate: [AdminGuard],
            },
            {path: "courses/:id", component: CourseOverviewComponent},
            {
                path: "teachers",
                component: TeachersListComponent,
                canActivate: [AdminGuard],
            },
            {path: "category/:id", component: CategoryCoursesComponent},
            {path: "news", component: NewsComponent, canActivate: [AdminGuard]},
            {
                path: "gallery",
                component: GalleryComponent,
                canActivate: [AdminGuard],
            },
            {
                path: "about-us",
                component: AboutUsComponent,
                canActivate: [AdminGuard],
            },
            {
                path: "classes",
                component: ClassesComponent,
                canActivate: [AdminGuard],
            },
            {path: "shop", component: ShopComponent, canActivate: [AdminGuard]},
            {path: "cart", component: CartComponent, canActivate: [AdminGuard]},
            {path: "social-app", component: SocialAppComponent},
            {
                path: "createcourse",
                component: CreateCourseComponent,
                canActivate: [AdminGuard],
            },
            {
                path: "editcourse/:id",
                component: EditCourseComponent,
                canActivate: [AdminGuard],
            },
            {path: "video", component: VideoComponent, canActivate: [AdminGuard]},
            {path: "live", component: LiveComponent, canActivate: [AdminGuard]},
            {
                path: "broad-cast",
                component: BroadCastComponent,
                canActivate: [AdminGuard],
            },
            {
                path: "payment-status",
                component: PaymentStatus,
            },
        ],
    },
    {
        path: "auth/login",
        component: LoginComponent,
    },
    {
        path: "auth/signup",
        component: SignupPortalComponent,
    },
    {
        path: "auth/signup/new/student",
        component: SignupStudentComponent,
    },
    {
        path: "auth/signup/new/teacher",
        component: SignupTeacherComponent,
    },
    {
        path: "**",
        redirectTo: "",
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // preloadingStrategy: PreloadAllModules,
            anchorScrolling: "enabled",
            scrollPositionRestoration: "enabled",
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
