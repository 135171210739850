import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-task',
  styleUrls: ['./add-task.component.css'],
  template: `<div class="panel panel-default">
  <div class="panel-heading" role="tab">
        <h4 class="panel-title">
            <a role="button" data-toggle="collapse" data-parent="#accordion"  href="#collapsegenerateCourseId" aria-expanded="true" aria-controls="generateCourseId">
                title
            </a>
        </h4>
        <div id="collapse" class="panel-collapse collapse in " role="tabpanel" aria-labelledby="generateCourseId">
            <div class="panel-body">
                desc
                <div id="generateCourseId">
                 </div>
             </div>
             <div>
                 <button id="generateCourseId" onclick='CreateNewLesson(this.id)' type="submit" class="playButton">
                   <span><i class="fas fa-play-circle"></i> Create</span>
                 </button>
             </div>
        </div>
    </div>
</div>`
})
export class AddTaskComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
