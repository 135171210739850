import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr'
import { UserService } from 'src/app/shared/services/user/authentication/user.service';
import { PortalUser } from 'src/app/shared/services/user/user.model';
import { MatDialog } from '@angular/material/dialog';
import { ReminderComponent } from '../reminder/reminder.component';

@Component({
  selector: 'app-signup-student',
  templateUrl: './signup-student.component.html',
  styleUrls: ['./signup-student.component.css']
})
export class SignupStudentComponent implements OnInit {

  user: PortalUser;

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  constructor(private userService: UserService, private toastr: ToastrService, private router: Router, public dialog: MatDialog) { }

  ngOnInit() {
    this.resetForm();
  }

  
  resetForm(form?: NgForm) {
    if (form != null)
      form.reset();
    this.user = {
      ID: 0,

      Email: '',
      Password: '',

      LoginID: '',

      FirstName: '',
      LastName: '',

      userType: 'Student',
      UserTypeID: 4,
      profileImage: 'https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg',
      profileName: ''
    }
  }

  OnSubmit(form: NgForm) {
    const dialogRef = this.dialog.open(ReminderComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          // console.log("Result is TRUE!");
          this.userService.registerUser(form.value)
        .subscribe((data: any) => {
          // console.log(data);
          this.router.navigate(['/auth/login']);
        });
      }
      else {
        // console.log("Result IS FALSE")
        this.router.navigate(['/auth/signup']);
      }
    });
  }

  onKeyUp(event) {
    //console.log(event);
  }
}
