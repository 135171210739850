import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import {
  Course,
  courseAssets,
} from "src/app/shared/services/course/course.model";
import { CourseService } from "src/app/shared/services/course/course.service";
import {
  FormGroup,
  FormControl,
  NgForm,
  FormBuilder,
  FormArray,
  Validators,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { S3uploadService } from "src/app/shared/services/s3upload.service";
import { ImageUploaderComponent } from "../../components/image-uploader/image-uploader.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-create-course",
  templateUrl: "./create-course.component.html",
  styleUrls: ["./create-course.component.css"],
})
export class CreateCourseComponent implements OnInit {
  course: Course;
  courseImgs: courseAssets;
  user: any;
  courseForm: FormGroup;
  imgDisplay;
  thumbNailDisplay;
  videoDisplay;
  urlImg;
  urlThumbNail;
  urlVideo;
  format;
  imgFile: any;
  thmbFile: any;
  videoFile: any;

  @ViewChild("videoInp")
  myvideoInp: ElementRef;

  @ViewChild("thumbInp")
  myThumbInp: ElementRef;

  @ViewChild("imgInp")
  myImgInp: ElementRef;
  httpClient: HttpClient;

  constructor(
    private courseService: CourseService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private uploadService: S3uploadService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.user = JSON.parse(localStorage.getItem("edGlobCurrentUser"));
    const subject_filling = this.getRandomString(6);
    this.resetForm();
  }

  ngOnInit() {
    console.log(this.user);
  }

  resetForm() {
    const subject_filling = this.getRandomString(6);
    this.courseForm = this.formBuilder.group({
      ID: [0],
      GradeId: [Validators.required],
      CourseReferenceNo: [""],
      CourseTitle: ["", Validators.required],
      whatULearn: ["", Validators.required],
      CourseDescription: ["", Validators.required],
      TeacherId: [this.user.loggedUserId],
      SubjectId: [Validators.required],
      Fee: [Validators.required],
      NoOfLessons: [Validators.required],
      StartDate: [""],
      EndDate: [""],
      IsActive: [1],
      NoOfSeat: [Validators.required],
      SchoolRefNo: [""],
      NoOfReview: [0],
      AverageRating: [0],
      Enroll: [1],
    });
  }

  get courseF() {
    return this.courseForm.controls;
  }
  get courseFArray() {
    return this.courseF.qualifications as FormArray;
  }

  OnSubmit() {
    let fileResult = false;
    if (this.courseForm.invalid) {
      alert("Invalid values in form");
      return;
    }
    this.courseService.createCourse(this.courseForm.value).subscribe(
      (data: any) => {
        if (data != 0 && data != false) {
          this.toastr.success("Record inserted", "Success");
          if (
            this.urlImg != "" &&
            this.urlImg != null &&
            this.urlImg != undefined
          ) {
            this.uploadSave(this.imgDisplay, this.imgFile, data);
            this.urlImg = null;
            this.myImgInp.nativeElement.value = "";
          }
          if (
            this.urlThumbNail != "" &&
            this.urlThumbNail != null &&
            this.urlThumbNail != undefined
          ) {
            this.uploadSave(this.thumbNailDisplay, this.thmbFile, data);
            this.urlThumbNail = null;
            this.myThumbInp.nativeElement.value = "";
          }
          if (
            this.urlVideo != "" &&
            this.urlVideo != null &&
            this.urlVideo != undefined
          ) {
            this.uploadSave(this.videoDisplay, this.videoFile, data);
            this.urlVideo = null;
            this.myvideoInp.nativeElement.value = "";
          }
          this.resetForm();
          console.log(data);
        } else {
          this.toastr.error("Please try again", "Error");
        }
      },
      (err) => {
        this.toastr.error("Please try again", "Error");
      }
    );
  }

  getRandomString(length) {
    var randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

  onSelectFile(event, type) {
    const file = event.target.files && event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf("image") > -1 && type == "image") {
        this.format = "image";
        this.imgFile = file;
        reader.onload = (event) => {
          this.imgDisplay = (<FileReader>event.target).result;
        };
        this.urlImg = this.myImgInp.nativeElement.value.split("\\")[2];
      } else if (file.type.indexOf("video") > -1 && type == "video") {
        this.format = "video";
        this.videoFile = file;
        reader.onload = (event) => {
          this.videoDisplay = (<FileReader>event.target).result;
        };
        this.urlVideo = this.myvideoInp.nativeElement.value.split("\\")[2];
      } else if (file.type.indexOf("image") > -1 && type == "thumbNail") {
        this.format = "image";
        this.thmbFile = file;
        reader.onload = (event) => {
          this.thumbNailDisplay = (<FileReader>event.target).result;
        };
        this.urlThumbNail = this.myThumbInp.nativeElement.value.split("\\")[2];
      } else {
        return;
      }
    }
  }

  deleteFile(type) {
    if (type == "image") {
      this.format = "";
      this.urlImg = null;
      this.myImgInp.nativeElement.value = "";
    } else if (type == "video") {
      this.format = "";
      this.urlVideo = null;
      this.myvideoInp.nativeElement.value = "";
    } else if (type == "thumbNail") {
      this.format = "";
      this.urlThumbNail = null;
      this.myThumbInp.nativeElement.value = "";
    } else {
      return;
    }
  }

  uploadSave(url, file, courseId) {
    let fileName = file.name.split(".");
    let result = this.uploadService.uploadCropedImage(
      url,
      "courses",
      fileName[0]
    );
    result.then((res) => {
      console.log(res);
      if (res.data.Location) {
        this.courseService
          .saveCourseAssets(res.data.Location, courseId)
          .subscribe(
            (res) => {
              console.log(res);
            },
            (err) => {
              console.log();
            }
          );
      }
    });
  }

  uploadVideoSave(url, file, courseId) {
    let fileName = file.name.split(".");
    let result = this.uploadService.uploadCourseVideo(
      url,
      "courses",
      fileName[0]
    );
    result.then((res) => {
      console.log(res);
      if (res.data.Location) {
        this.courseService
          .saveCourseAssets(res.data.Location, courseId)
          .subscribe(
            (res) => {
              console.log(res);
            },
            (err) => {
              console.log();
            }
          );
      }
    });
  }
}
