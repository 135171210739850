import { Component, OnInit } from '@angular/core';
import * as chartData from './../../../shared/data/dashboard/e-commerce';
declare var require: any
var Knob = require('knob') // browserify require

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  public date : any
  constructor() { }

  ngOnInit():void {
    
  }
  public slidesStore = [{
    id: 1,
    icon: 'dollar-sign',
    title: 'Total Earning',
    number: 72
  },
  {
    id: 2,
    icon: 'map-pin',
    title: 'Total Web Visitor',
    number: 65
  },
  {
    id: 3,
    icon: 'shopping-cart',
    title: 'Total Sale Product',
    number: 96
  },
  {
    id: 4,
    icon: 'trending-down',
    title: 'Company Loss',
    number: 89
  }]
  
}
