import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Course } from "src/app/core/models/course";
import { Category } from "src/app/core/models/category";
import { Instructor } from "src/app/core/models/instructor";
import { Observable } from "rxjs";
// import { type } from "os";

@Injectable({
  providedIn: "root",
})
export class CoursesService {
  private getStudentsUrl =
    "https://edglob.net/eduapi/api/User/GetAllStudents";
  private getTeachersUrl =
    "https://edglob.net/eduapi/api/User/GetAllTeachers";
  private searchTeachersUrl =
    "https://edglob.net/eduapi/api/User/SearchTeachers?";
  private courseDropdownUrl =
    "https://edglob.net/eduapi/api/Course/GetCourseSchool";
  private subjectDropdownUrl =
    "https://edglob.net/eduapi/api/Course/GetCourseSubject";
  private gradeDropdownUrl =
    "https://edglob.net/eduapi/api/Course/GetCourseGrade";
  constructor(private http: HttpClient) {}

  getAllCourses() {
    return this.http.get(
      "https://edglob.net/eduapi/api/Course/GetCourseList"
    );
  }

  getAllTeachers() {
    return this.http.get<any>(this.getTeachersUrl);
  }

  searchAllTeachers(
    subjectId,
    gradeId,
    schoolId,
    courseTitle,
    country,
    university,
    qualification,
    gender,
    age,
    level,
    background
  ) {
    let params = new HttpParams();

    if (subjectId) {
      params = params.append("subjectId", subjectId);
    }
    if (gradeId) {
      params = params.append("gradeId", gradeId);
    }
    if (schoolId) {
      params = params.append("schoolId", schoolId);
    }
    if (courseTitle) {
      params = params.append("courseTitle", courseTitle);
    }
    if (country) {
      params = params.append("country", country);
    }
    if (university) {
      params = params.append("university", university);
    }
    if (qualification) {
      params = params.append("qualification", qualification);
    }
    if (gender) {
      params = params.append("gender", gender);
    }
    if (age) {
      params = params.append("age", age);
    }
    if (level) {
      params = params.append("level", age);
    }

    return this.http.get<any>(this.searchTeachersUrl, { params: params });
  }

  getAllStudents() {
    return this.http.get<any>(this.getStudentsUrl);
  }

  getAllCategories(): Category[] {
    return COURSES.map((item) => {
      return item.courseCategory;
    });
  }

  getAllInstructor(): Instructor[] {
    return COURSES.map((item) => {
      return item.courseInstructor;
    });
  }

  getTopCourses(): Course[] {
    return COURSES.slice(0, 3);
  }

  getRecentCourses(): Course[] {
    return COURSES.slice(3, 6);
  }

  getCourseById(id: number): Observable<any> {
    return this.http.get(
      `https://edglob.net/eduapi/api/Course/GetCourseDetailsById?courseId=${id}`
    );
  }

  getCoursesByInstructorId(id: number): Observable<any> {
    return this.http.get(
      `https://edglob.net/eduapi/api/Course/GetCourseListByTeacher?teacherId=${id}`
    );
  }

  deleteCourse(id: number): Observable<any> {
    return this.http.post(
      `https://edglob.net/eduapi/api/Course/DeleteCourse?courseId=${id}`,
      {}
    );
  }

  getCoursesByCategoryId(id: number): Observable<any> {
    return this.http.get(
      "https://edglob.net/eduapi/api/Course/GetCourseList"
    );
  }

  fetchCourse() {
    return this.http.get(
      "https://edglob.net/eduapi/api/Course/GetCourseList"
    );
  }

  fetchSchools() {
    return this.http.get(this.courseDropdownUrl);
  }

  fetchGrade() {
    return this.http.get(this.gradeDropdownUrl);
  }

  fetchSubjects() {
    return this.http.get(this.subjectDropdownUrl);
  }
}

export const COURSES: Course[] = [
  
  {
    $id: 3,
    ID: 3,
    CourseReferenceNo: 25,
    CourseTitle: "Chemistry Adavance",
    CourseDescription:
      "Chemistry is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    whatULearn: [
      "Read Chemistry",
      "construct Sentences",
      "review grammar",
      "practice oral with tutor and other students",
    ],
    courseRequirement: [
      "get at least 3 year old",
      "Basic english",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
    ],
    NoOfLessons: 12,
    NoOfSeat: 10,
    StartDate: new Date("2020-10-01T00:00:00"),
    EndDate: new Date("2020-12-03T00:00:00"),
    IsActive: false,
    NoOfReview: 90,
    AverageRating: 4.1,
    courseSchool: {
      SchoolRefNo: "SG_BRAIN_2",
      SchoolName: "Brain Tution Center",
      schoolId: 3,
    },
    courseCategory: {
      SubjectId: 3,
      SubjectName: "Languages",
    },
    courseInstructor: {
      TeacherId: 3,
      TeacherName: "Vickie",
    },
    Enroll: 208,
    ImageUrl: "course3.jpg",
    Fee: 105,
    TeacherName: "demo",
    SubjectName: "demo1",
    type: "",
    CourseVideoUrl: "",
  },
  {
    $id: 4,
    ID: 4,
    CourseReferenceNo: 26,
    CourseTitle: "Primary English / Hindi",
    CourseDescription:
      "Hindi is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    whatULearn: [
      "Read Hindi",
      "construct Sentences",
      "review grammar",
      "practice oral with tutor and other students",
    ],
    courseRequirement: [
      "get at least 3 year Hindi",
      "Basic english",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
    ],
    NoOfLessons: 10,
    NoOfSeat: 10,
    StartDate: new Date("2020-10-01T00:00:00"),
    EndDate: new Date("2020-12-03T00:00:00"),
    IsActive: false,
    NoOfReview: 60,
    AverageRating: 3.9,
    courseSchool: {
      SchoolRefNo: "SG_BIRD_2",
      SchoolName: "Birdway Learning Center",
      schoolId: 3,
    },
    courseCategory: {
      SubjectId: 3,
      SubjectName: "Math",
    },
    courseInstructor: {
      TeacherId: 4,
      TeacherName: "Vickie",
    },
    Enroll: 208,
    ImageUrl: "course4.jpg",
    Fee: 100,
    TeacherName: "demo",
    SubjectName: "demo1",
    type: "",
    CourseVideoUrl: "",
  },
  {
    $id: 5,
    ID: 5,
    CourseReferenceNo: 27,
    CourseTitle: "Essaie Writing",
    CourseDescription:
      "Writing is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    whatULearn: [
      "Read english",
      "construct Sentences",
      "review grammar",
      "practice oral with tutor and other students",
    ],
    // tslint:disable-next-line:max-line-length
    courseRequirement: [
      "get at least Essaie Writing",
      "Basic english",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
    ],
    NoOfLessons: 10,
    NoOfSeat: 10,
    StartDate: new Date("2020-10-01T00:00:00"),
    EndDate: new Date("2020-12-03T00:00:00"),
    IsActive: true,
    NoOfReview: 50,
    AverageRating: 3.8,
    courseSchool: {
      SchoolRefNo: "SG_BIRD_2",
      SchoolName: "Birdway Learning Center",
      schoolId: 3,
    },
    courseCategory: {
      SubjectId: 3,
      SubjectName: "Science",
    },
    courseInstructor: {
      TeacherId: 5,
      TeacherName: "Brian",
    },
    Enroll: 188,
    ImageUrl: "course5.jpg",
    Fee: 200,
    TeacherName: "demo",
    SubjectName: "demo1",
    type: "",
    CourseVideoUrl: "",
  },
  {
    $id: 6,
    ID: 6,
    CourseReferenceNo: 28,
    CourseTitle: "Business Leadership",
    CourseDescription:
      "Business is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.variations of passages",
    whatULearn: [
      "Read Business",
      "construct Sentences",
      "review grammar",
      "practice oral with tutor and other students",
    ],
    courseRequirement: [
      "get at least 3 year Business",
      "Basic english",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
    ],
    NoOfLessons: 10,
    NoOfSeat: 10,
    StartDate: new Date("2020-10-01T00:00:00"),
    EndDate: new Date("2020-12-03T00:00:00"),
    IsActive: true,
    NoOfReview: 100,
    AverageRating: 4.7,
    courseSchool: {
      SchoolRefNo: "SG_BIZZ_1",
      SchoolName: "Bizz Class Center",
      schoolId: 4,
    },
    courseCategory: {
      SubjectId: 4,
      SubjectName: "Art",
    },
    courseInstructor: {
      TeacherId: 5,
      TeacherName: "Jeannette",
    },
    Enroll: 108,
    ImageUrl: "course6.jpg",
    Fee: 250,
    TeacherName: "demo",
    SubjectName: "demo1",
    type: "",
    CourseVideoUrl: "",
  },
  {
    $id: 7,
    ID: 7,
    CourseReferenceNo: 29,
    CourseTitle: "Essaie Writing",
    CourseDescription:
      "Writing is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    whatULearn: [
      "Read english",
      "construct Sentences",
      "review grammar",
      "practice oral with tutor and other students",
    ],
    courseRequirement: [
      "get at least 3 year old",
      "Basic english",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
    ],
    NoOfLessons: 10,
    NoOfSeat: 10,
    StartDate: new Date("2020-10-01T00:00:00"),
    EndDate: new Date("2020-12-03T00:00:00"),
    IsActive: false,
    NoOfReview: 50,
    AverageRating: 4.1,
    courseSchool: {
      SchoolRefNo: "SG_BIRD_2",
      SchoolName: "Birdway Learning Center",
      schoolId: 3,
    },
    courseCategory: {
      SubjectId: 3,
      SubjectName: "Sports",
    },
    courseInstructor: {
      TeacherId: 7,
      TeacherName: "Brian",
    },
    Enroll: 188,
    ImageUrl: "course7.jpg",
    Fee: 200,
    TeacherName: "demo",
    SubjectName: "demo1",
    type: "",
    CourseVideoUrl: "",
  },
  {
    $id: 8,
    ID: 8,
    CourseReferenceNo: 30,
    CourseTitle: "Math k2 Advance",
    CourseDescription:
      "Math is a journey key are many variations, a long established fact that a reader will be distracted by the readable content of a page when looking at its layout",
    whatULearn: [
      "Read english",
      "construct Sentences",
      "review grammar",
      "practice oral with tutor and other students",
    ],
    courseRequirement: [
      "get at least 3 year old",
      "Basic english",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
    ],
    NoOfLessons: 10,
    NoOfSeat: 10,
    StartDate: new Date("2020-10-01T00:00:00"),
    EndDate: new Date("2020-12-03T00:00:00"),
    IsActive: true,
    NoOfReview: 50,
    AverageRating: 3.5,
    courseSchool: {
      SchoolRefNo: "SG_SPRINGVALE_1",
      SchoolName: "Springvale Education",
      schoolId: 1,
    },
    courseCategory: {
      SubjectId: 1,
      SubjectName: "Skills/Technologies",
    },
    courseInstructor: {
      TeacherId: 7,
      TeacherName: "Mary",
    },
    Enroll: 108,
    ImageUrl: "course7.jpg",
    Fee: 200,
    TeacherName: "demo",
    SubjectName: "demo1",
    type: "",
    CourseVideoUrl: "",
  },
  
];
