import { Component, Inject, OnInit } from "@angular/core";
import { CourseService } from "src/app/shared/services/course/course.service";
import { FormGroup, FormBuilder, FormArray, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { retry } from "rxjs/operators";

@Component({
  selector: "app-edit-topic",
  templateUrl: "./edit-topic.component.html",
  styleUrls: ["./edit-topic.component.css"],
})
export class EditTopicComponent implements OnInit {

  topic:any;
  topicForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditTopicComponent>,
    private formBuilder: FormBuilder,
    public courseService: CourseService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.topic = data;
    this.resetTopicForm(this.topic);
  }

  ngOnInit() {
    
  }

  resetTopicForm(topic: any) {
    this.topicForm = this.formBuilder.group({
      ID: [topic.TopicId],
      CourseId: [topic.CourseId],
      CourseTopicDescription: [topic.TopicDescription, Validators.required],
      CourseTopicTitle: [topic.TopicTitle, Validators.required],
      TopicTime: [topic.TopicTime,Validators.required],
    });
  }

  onSubmit(){
    if(this.topicForm.invalid){
      alert('Invalid values in form');
      return;
    } 
    this.courseService.updateTopic(this.topicForm.value).subscribe(x =>{
      if(x){
        this.onNoClick(x)
      }
    })
  }

  onNoClick(result): void {
    this.dialogRef.close(result);
  }

}

