import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserService } from "src/app/shared/services/user/authentication/user.service";

@Component({
  selector: "app-update-password",
  templateUrl: "./update-password.component.html",
  styleUrls: ["./update-password.component.css"],
})
export class UpdatePasswordComponent implements OnInit {

  userLoginData :any ;
  oldPassword:string;
  newPassword:string;
  newPassword2:string;
  passwordMatchError:boolean;
  oldPasswordError:string;

  constructor(
    public dialogRef: MatDialogRef<UpdatePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService
  ) {
    this.userLoginData = data;
  }

  ngOnInit(): void {

  }

  onSubmit() {

    if(this.newPassword != this.newPassword2) {
      this.passwordMatchError = true;
      return;
    }
    else  this.passwordMatchError = false;
    this.userLoginData.Password = this.newPassword2

    this
      this.userService
      .updateUserLogin(this.userLoginData)
      .subscribe((x) => {
        if (x != 0 && x != false) this.onNoClick(true);
      });
  
  }

  onNoClick(result): void {
    this.dialogRef.close(result);
  }
}
