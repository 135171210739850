import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-broad-cast",
  templateUrl: "./broad-cast.component.html",
  styleUrls: ["./broad-cast.component.css"],
})
export class BroadCastComponent implements OnInit {
   constructor(private route: Router) {}
 
   ngOnInit(): void {}
  ngAfterViewInit() {
     this.route.navigate(["https://atmvideochat.com/streaming.aspx"]);
    window.open("https://atmvideochat.com/streaming.aspx", "");
   }
 } 
 

 
