import {Component, OnInit} from "@angular/core";
import {CartService} from "src/app/core/services/cart.service";
import {Location} from "@angular/common";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
    selector: "app-cart",
    templateUrl: "./cart.component.html",
    styleUrls: ["./cart.component.css"],
})
export class CartComponent implements OnInit {
    userCartNumber = 0;
    url: string = "";
    urlSafe: SafeResourceUrl;

    constructor(
        private location: Location,
        public cartService: CartService,
        private _snackBar: MatSnackBar,
        public sanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        this.invokeStripe();
        this.cartService
            .getUserCartDetails(JSON.parse(localStorage.getItem("edGlobuserInfo")).ID)
            .subscribe((x) => {
                const {CartInfoList} = x;
                if (CartInfoList && CartInfoList.length > 0) {
                    this.userCartNumber = CartInfoList[0].ID;
                    console.log(this.userCartNumber);
                    this.url = `https://stripe.atmcomm.com/Default?cart_id=${this.userCartNumber}`;
                    //this.url = `https://www.atmcomm.com/_stripe/Default?cart_id=${this.userCartNumber}`;
                    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
                        this.url
                    );
                }
            });
    }

    getHostname(): string {
        if (document.location.hostname.includes('localhost')) {
            return 'http://' + document.location.host;
        }
        return 'https://edglob.net';
    }

    makePayment(amount: any) {

        const customerName = JSON.parse(localStorage.getItem("edGlobuserInfo")).LoginID;
        const Products = [];
        for (const item of this.cartService.cartItems) {
            Products.push({
                ProductId: item.CourseId,
                ProductName: item.CourseTitle,
                Quantity: item.Quantity,
                UnitAmountDecimal: item.Price
            });
        }

        const req = {
            customerName,
            SuccessUrl: `${this.getHostname()}/payment-status?status=success&amount=${this.cartService.cartTotal}`,
            CancelUrl: `${this.getHostname()}/payment-status?status=canceled`,
            Currency: 'SGD',
            Products
        };

        console.log(req);

        // return;

        this.cartService
            .doPayment(req)
            .subscribe((x) => {
                console.log(x);
            });


    }

    makePaymentOld(amount: any) {


        const paymentHandler = (<any>window).StripeCheckout.configure({
            key: "pk_test_8v1skG9b3N1L9KBq7HxAmxP5",
            locale: "auto",
            token: function (stripeToken: any) {
                console.log('Token----------------------', stripeToken.card);
            },
        });

        paymentHandler.open({
            name: "Edglob",
            description: `${this.cartService.cartItemsCount} items added`,
            amount: this.getTotal() * 100,
        });
    }

    // public ngAfterViewInit() {
    //   this.stripeCheckoutLoader
    //     .createHandler({
    //       key: "pk_test_8v1skG9b3N1L9KBq7HxAmxP5",
    //       token: (token) => {
    //         // Do something with the token...
    //         console.log("Payment successful!", token);
    //       },
    //     })
    //     .then((handler: StripeCheckoutHandler) => {
    //       this.stripeCheckoutHandler = handler;
    //     });
    // }

    // public onClickBuy() {
    //   this.stripeCheckoutHandler.open({
    //     amount: 1500,
    //     currency: "EUR",
    //   });
    // }

    // public onClickCancel() {
    //   // If the window has been opened, this is how you can close it:
    //   this.stripeCheckoutHandler.close();
    // }

    /*
    handler: StripeCheckoutHandler;

    confirmation: any;
    loading = false;

    ngOnInit(): void {
      this.handler = StripeCheckout.configure({
        key: "pk_test_8v1skG9b3N1L9KBq7HxAmxP5",
        locale: "auto",
        source: async (source) => {
          this.loading = true;
          const user = localStorage.getItem("edGlobuserInfo");
          this.loading = false;
          this.cartService.emptyCart();
          this._snackBar.open("Payment made successfully", "", {
            duration: 3000,
            panelClass: ["green-snackbar"],
          });

        },
      });
    }

    async checkout(e) {
      const user = JSON.parse(localStorage.getItem("edGlobuserInfo"));
      this.handler.open({
        name: 'Purchase of courses',
        description: this.getDescription(),
        amount: this.getTotal() * 100,
        email: user.Email,
      });
      // e.preventDefault();
    }

    // Close on navigate
    @HostListener('window:popstate')
    onPopstate() {
      this.handler.close();
    }

    */

    removeCourse(cartid, itemid) {
        this.cartService.removeCourse(cartid, itemid).subscribe((x) => {
        });
    }

    getTotal() {
        return this.cartService.cartTotal;
    }

    continueShoppingBtn() {
        this.location.back();
    }

    invokeStripe() {
        if (!window.document.getElementById("stripe-script")) {
            const script = window.document.createElement("script");
            script.id = "stripe-script";
            script.type = "text/javascript";
            script.src = "https://checkout.stripe.com/checkout.js";
            window.document.body.appendChild(script);
        }
    }
}
