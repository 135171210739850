import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { S3uploadService } from "src/app/shared/services/s3upload.service";

@Component({
  selector: "app-image-uploader",
  templateUrl: "./image-previewer.component.html",
  styleUrls: ["./image-previewer.component.css"],
})
export class ImagePreviewerComponent implements OnInit {
  srcUrl: any = null;
  constructor(
    public dialogRef: MatDialogRef<ImagePreviewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.srcUrl = data;
  }



  ngOnInit(): void {}

  onNoClick(result): void {
    this.dialogRef.close(result);
  }
}
