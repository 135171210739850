import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { CoursesService } from "src/app/shared/services/courses.service";
import { Router } from "@angular/router";
import { UserService } from "src/app/shared/services/user/authentication/user.service";
import { countries } from "../../Classes/teacherClass";

@Component({
  selector: "app-teachers-list",
  templateUrl: "./teachers-list.component.html",
  styleUrls: ["./teachers-list.component.css"],
})
export class TeachersListComponent implements OnInit {
  textShowLength = 20;
  teachers: any[] = [];
  countries: countries[] = [];
  userCountryList: any[] = [];
  subjectId = null;
  gradeId = null;
  schoolId = null;
  courseTitle = null;
  country = null;
  university = null;
  qualification = null;
  gender = null;
  age = null;
  background = null;
  level = null;
  courseDD: any[] = [];
  gradeDD: any[] = [];
  schoolDD: any[] = [];
  @ViewChild("subjectD")
  SubjectD: ElementRef;
  @ViewChild("genderD")
  GenderD: ElementRef;
  @ViewChild("ageD")
  AgeD: ElementRef;
  @ViewChild("qualificationD")
  QualificationD: ElementRef;
  @ViewChild("backgroundD")
  BackgroundD: ElementRef;
  @ViewChild("levelD")
  LevelD: ElementRef;
  selectedItems = [];
  settings = {};

  number = this.randomNumber();
  constructor(
    private coursesService: CoursesService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    // this.teachers = this.coursesService.getAllInstructor();
    this.getTeacherList();
    this.getSchoolDropdown();
    this.getGradeDropdown();
    this.getCourseDropdown();
    // this.teachersList();
    //this.getCountries();

    this.settings = {
      enableSearchFilter: true,
      addNewItemOnFilter: false, singleSelection: true, text: "Select Country"
    };
  }

  onViewCourses(id: number) {
    this.router.navigate(["/teachers/" + id]);
  }

  clear() {
    this.getTeacherList();
  }

  onItemSelect(item: any) {
    this.searchTeachers('country', item.Id)
  }
  OnItemDeSelect(item: any) {
    this.searchTeachers('country', 0)
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    this.searchTeachers('country', 0)
  }
  // onAddItem(data:string){
  //   this.count++;
  //   this.itemList.push({"id": this.count,"itemName":data,"name":data});
  //   this.selectedItems = [];
  //   this.selectedItems.push({"id": this.count,"itemName":data,"name":data});
  // }

  getTeacherList() {
    this.coursesService.getAllTeachers().subscribe(
      (response) => {
        this.teachers = response.UserList;


        let list = response.UserList.filter(x => x.Country != null)
        this.userCountryList = list.filter((a, i) => list.findIndex((s) => a.Country === s.Country) === i)
        this.getCountries();
      },
      (err) => { }
    );
  }

  searchTeachers(searchId, searchValue) {
    this.setValue(searchId, searchValue);

    this.coursesService
      .searchAllTeachers(
        this.subjectId,
        this.gradeId,
        this.schoolId,
        this.courseTitle,
        this.country,
        this.university,
        this.qualification,
        this.gender,
        this.age,
        this.level,
        this.background
      )
      .subscribe((response) => {
        this.teachers = response.UserList;
      });
  }

  setValue(searchId, searchValue) {
    switch (searchId) {
      case "country": {
        this.country = searchValue;
        break;
      }
      case "qualification": {
        this.qualification = searchValue;
        break;
      }
      case "university": {
        this.university = searchValue;
        break;
      }
      case "course": {
        this.courseTitle = searchValue;
        break;
      }
      case "subject": {
        this.subjectId = searchValue;
        break;
      }
      case "gender": {
        this.gender = searchValue;
        break;
      }
      case "age": {
        this.age = searchValue;
        break;
      }
      case "level": {
        this.level = searchValue;
        break;
      }
      case "background": {
        this.background = searchValue;
        break;
      }
    }
  }

  randomNumber() {
    return Math.floor(Math.random() * 11);
  }

  getSchoolDropdown() {
    this.coursesService.fetchSchools().subscribe((res) => {
      this.schoolDD = res["DropList"];
    });
  }

  getCountryDropdown() {
    this.coursesService.fetchSchools().subscribe((res) => {
      this.schoolDD = res["DropList"];
    });
  }
  getCourseDropdown() {
    this.coursesService.fetchSubjects().subscribe((res) => {
      this.courseDD = res["DropList"];
    });
  }

  getGradeDropdown() {
    this.coursesService.fetchGrade().subscribe((res) => {
      this.gradeDD = res["DropList"];
    });
  }

  // teachersList() {
  //   this.userService.getUsers().subscribe((res) => {
  //     let list = res["UserList"];
  //     list.forEach((element) => {
  //       if (element.Country != null) {
  //         let exists = this.countries
  //           .filter((x) => x.toLowerCase() == element.Country.toLowerCase())
  //           .toString();
  //         if (!exists) {
  //           this.countries.push(element.Country);
  //         }
  //       }
  //     });
  //     console.log(this.countries);
  //   });
  // }

  getCountries() {
    this.countries = []
    this.userService.getCountries().subscribe((res) => {
      this.userCountryList.forEach(element => {
        let obj = res.UserDropStringList.find(x => x.Id == element.Country)
        this.countries.push({ id: obj.Id, itemName: obj.Value })
      });
    });
  }
}
