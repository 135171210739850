import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormControl, NgForm } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { UserService } from 'src/app/shared/services/user/authentication/user.service';
import { PortalUser } from 'src/app/shared/services/user/user.model';
import { LoginUser } from 'src/app/shared/services/user/login.model';
import {UserInfo} from '../../shared/services/user/userInfo.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: LoginUser;


  constructor(
    private userService: UserService, private router: Router
  ) {

  }

  ngOnInit() {
    this.resetForm();
  }


  resetForm(form?: NgForm) {
    if (form != null) {
      form.reset();
    }
    this.user = {
      userid: '',
      password: ''
    };
  }

  OnSubmit(form: NgForm) {
    this.userService.loginUser(form.value)
      .subscribe((userInfo: UserInfo) => {
          const currentUser = {
              loggedUser: userInfo.Full_Name,
              loggedUserId: userInfo.ID,
              loggedUserType: userInfo.userType,
              loggedUserEmail: userInfo.Email,
          };
        this.userService.setLogIn();
        localStorage.setItem('edGlobCurrentUser', JSON.stringify(currentUser));
        localStorage.setItem('edGlobuserInfo', JSON.stringify(userInfo));
        this.userService.currentUserInfo$.next(userInfo);
        this.router.navigate(['/dashboard']);
      });
  }

}

