import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Course } from "src/app/core/models/course";
import { Category } from "src/app/core/models/category";
import { Instructor } from "src/app/core/models/instructor";
import { CourseContent } from "src/app/core/models/coursecontent";
import { Topic } from "src/app/core/models/topic";
import { Lesson } from "src/app/core/models/lesson";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CoursesContentService {
    constructor(private http: HttpClient){}
    getCourseContentById(id: number): Observable<CourseContent> {
		return this.http.get<CourseContent>(
			"https://edglob.net/eduapi/api/Course/GetCourseContentById?courseid="+id
		);
    }
}

