import { Component, OnInit } from '@angular/core';
import { CoursesService } from 'src/app/shared/services/courses.service';
import { AuthService } from 'src/app/shared/services/firebase/auth.service';
import { HttpClient } from '@angular/common/http';
import { Course } from 'src/app/core/models/course';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/shared/services/user/authentication/user.service';
import { UserInfo } from 'src/app/shared/services/user/userInfo.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public type: string;
  courses: Course[];
  topcourses: Course[];
  recentcourses: Course[];
  currentUser: UserInfo;

  public users: any;

  constructor(public courseService: CoursesService, private http: HttpClient, private authService: AuthService,public userService: UserService) {
  }
  totalAngularPackages;



  ngOnInit(): void {

    this.userService.currentUserInfo$.subscribe((userInfo)=>{
        this.currentUser = userInfo;
        console.log('currentUserInfo$',this.currentUser)
    });
    this.getUsers();

    //Get Top Courses
    this.http.get('https://edglob.net/eduapi/api/Course/GetCourseList').subscribe((response: CoursesResponse) => {
      this.courses = response.CourseList;
      //Get Top Courses
      this.topcourses = this.courses.slice(0, 3);
      //Get Recent Course
      this.recentcourses = this.courses.slice(3, 6);
    })

  }

  public getUsers(): void {
    this.userService.getUsers().subscribe(
      (response: UserInfo[]) => {
        this.users = response;
        console.log(this.users);
        
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      }
    );
  }
}
export interface CoursesResponse {
  $id: string;
  Result: null;
  CourseList: Course[];
}
