import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'
import { Topic } from 'src/app/shared/services/topic/topic.model';
import { TopicService } from 'src/app/shared/services/topic/topic.service';

@Component({
  selector: 'app-create-topic',
  templateUrl: './create-topic.component.html',
  styleUrls: ['./create-topic.component.css']
})
export class CreateTopicComponent implements OnInit {

  topic: Topic;

  constructor(private topicService: TopicService, private toastr: ToastrService) { }

  ngOnInit() {
    this.resetForm();
  }

  
  resetForm(form?: NgForm) {
    const filling = Math.floor((Math.random() * 100) + 1);
    if (form != null)
      form.reset();
    this.topic = {
      ID: 0,
      CourseId: filling,
      CourseTopicDescription: '',
      CourseTopicTitle: '',
      TopicTime: filling
    }
  }

  OnSubmit(form: NgForm) {
    this.topicService.createTopic(form.value)
      .subscribe((data: any) => {

      });
  }
}
