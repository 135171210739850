import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class CartService {
    api_url: string = "https://edglob.net/eduapi/api/CartPayment/";
    stripe_api_url: string = "https://edglob.net/Stripe/api/";

    //

    constructor(private http: HttpClient) {
    }

    public cartItems = [];
    public cartTotal: number = 0;
    public cartItemsCount: number = 0;


    doPayment(formData): Observable<any> {
        return this.http
            .post(`${this.stripe_api_url}Payment`, formData)
            .map((x: any) => {
                console.log('Payment', x);
                window.location.href = x;
                return x;
            });
    }

    getUserCartDetails(id): Observable<any> {
        return this.http
            .get(`${this.api_url}GetCartInfoByUserId?userId=${id}`)
            .map((x: any) => {
                console.log('GetCartInfoByUserId', x);
                this.setCartData(x);
                return x;
            });
    }

    addCourse(userid, courseId, quantity, price, discount): Observable<any> {
        return this.http
            .post(
                `${this.api_url}AddItemTocart?userid=${userid}&courseId=${courseId}&quantity=${quantity}&price=${price}&discount=${discount}`,
                {}
            )
            .map((x: any) => {
                this.setCartData(x);

                return x;
            });
    }

    removeCourse(cartid, itemid): Observable<any> {
        console.log("removeCourse", cartid, itemid);
        return this.http
            .post(
                `${this.api_url}DeleteItemFromCart?cartItemId=${itemid}&cartId=${cartid}`,
                {}
            )
            .map((x) => {
                this.setCartData(x);
                return x;
            });
    }

    setCartData(response) {
        if (response.CartInfoList.length == 0) {
            this.cartTotal = 0;
            this.cartItems = [];
            this.cartItemsCount = 0;
            return;
        }
        if (response.CartInfoList.length > 0) {
            this.cartTotal = response.CartInfoList[0].Total;
        }
        if (
            Object.keys(response).includes("CartInfoList") &&
            response.CartInfoList.length > 0
        ) {
            this.cartItems = response.CartInfoList[0].CartLineItems;
            this.cartItemsCount = this.cartItems.length;
        }
    }

    emptyCart() {
        this.cartItems = [];
        this.cartItemsCount = 0;
    }
}
