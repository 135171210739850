import { Component, Inject, OnInit } from "@angular/core";
import { CourseService } from "src/app/shared/services/course/course.service";
import { FormGroup, FormBuilder, FormArray, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { S3uploadService } from "src/app/shared/services/s3upload.service";

@Component({
  selector: "app-edit-lesson",
  templateUrl: "./edit-lesson.component.html",
  styleUrls: ["./edit-lesson.component.css"],
})
export class EditLessonComponent implements OnInit {
  lesson: any;
  lessonForm: FormGroup;
  lessonContent = "";
  lessonThumnail: string = null;
  lessonAttacments = [];
  homeworkAttachmentType = 0;
  homeworkAttachmentDescription = ''

  constructor(
    public dialogRef: MatDialogRef<EditLessonComponent>,
    private formBuilder: FormBuilder,
    public courseService: CourseService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private uploadService: S3uploadService
  ) {
    this.initializeComponent(data);
  }

  initializeComponent(data: any) {
    this.lesson = data;
    this.lessonContent = this.lesson.lessonContentunsafe;
    this.resetLessonForm(this.lesson);

    if (this.lesson.FeatureImg != "" && this.lesson.FeatureImg != null) {
      this.lessonThumnail = this.lesson.FeatureImg;
    }

    if (
      this.lesson.uploadAtachment != null &&
      this.lesson.uploadAtachment != ""
    ) {
      this.lessonAttacments = this.lesson.uploadAtachment.split(",");
    }
  }

  ngOnInit() {}

  selectedLessonThumbnail: File;
  selectedLessonAttachements: FileList;
  selectedHomeworkAttachements: any = [];

  selectFile(event, itemType) {
    if (itemType == "thumbnail")
      this.selectedLessonThumbnail = event.target.files[0];
    if (itemType == "attachment")
      this.selectedLessonAttachements = event.target.files;
    if (itemType == "homework")
      this.selectedHomeworkAttachements = event.target.files;
  }

  resetLessonForm(lesson: any) {
    this.lessonForm = this.formBuilder.group({
      ID: [this.lesson.LessonId],
      CourseId: [this.lesson.CourseId],
      CourseTopicId: [this.lesson.CourseTopicId],
      Title: [this.lesson.LessonTitle, Validators.required],
      Description: [this.lesson.LessonDescription, Validators.required],
      lessonContent: [this.lesson.lessonContent],
      UTCStartTime: [this.lesson.LessonStartTime],
      UTCEndTime: [this.lesson.LessonEndTime],
      ClassRoomNo: [this.lesson.ClassRoomNo],
      ClassRoomLink: [this.lesson.ClassRoomLink],
      timeRequire: [this.lesson.TimeRequire, Validators.required],
      FeatureImg: [this.lesson.FeatureImg],
      uploadAtachment: [this.lesson.uploadAtachment],
    });
  }

  removeThumbnailImage() {
    this.lessonForm.patchValue({
      FeatureImg: "",
    });

    this.lessonThumnail = null;
  }

  removeAttachment(index) {
    this.lessonAttacments.splice(index, 1);
  }

  async onSubmit() {
    if (
      this.lesson.lessonContentunsafe == "" ||
      this.lesson.lessonContentunsafe == null
    ) {
      alert("Please add lesson content");
      return;
    }

    this.lessonForm.patchValue({
      lessonContent: this.lesson.lessonContentunsafe,
    });

    if (this.lessonForm.invalid) {
      alert("Invalid values in form");
      return;
    }

    let thumnailData;
    if (this.selectedLessonThumbnail) {
      var resp = await this.uploadService.uploadFile(
        this.selectedLessonThumbnail,
        "courses"
      );
      thumnailData = resp.data.Location;
    }

    let courseMaterialData = "";
    if (this.selectedLessonAttachements) {
      for (let i = 0; i < this.selectedLessonAttachements.length; i++) {
        var temp = await this.uploadService.uploadFile(
          this.selectedLessonAttachements[i],
          "courses"
        );
        courseMaterialData += temp.data.Location + ",";
      }

      if (this.lessonAttacments.length == 0)
        courseMaterialData = courseMaterialData.substring(
          0,
          courseMaterialData.length - 1
        );
    }

    this.lessonAttacments.forEach((element) => {
      courseMaterialData += element + ",";
    });

    if (this.lessonAttacments.length > 0)
      courseMaterialData = courseMaterialData.substring(
        0,
        courseMaterialData.length - 1
      );

    if (thumnailData && thumnailData != null && thumnailData != "")
      this.lessonForm.patchValue({
        FeatureImg: thumnailData,
      });

    if (
      courseMaterialData &&
      courseMaterialData != null &&
      courseMaterialData != ""
    )
      this.lessonForm.patchValue({
        uploadAtachment: courseMaterialData,
      });

    this.courseService.updateLesson(this.lessonForm.value).subscribe((x) => {
      if (x) {
        this.onNoClick(x);
      }
    });
  }

  async saveHomeworkAttachment(){
    var res = '';
    for (let i = 0; i < this.selectedHomeworkAttachements.length; i++) {
      var temp = await this.uploadService.uploadFile(
        this.selectedHomeworkAttachements[i],
        "courses"
      );
      res += temp.data.Location + ",";
    }

    res = res.substring(0,res.length - 1);
    var body = {
      LessonID: this.lesson.LessonId,
      FileUrl: res,
      Description: this.homeworkAttachmentDescription,
      IsActive: 5,
      LessonType: this.homeworkAttachmentType
    }
    this.courseService.saveHomework(body).subscribe((x) => {
      if (x) {
        this.onNoClick(x);
      }
    });

  }

  onNoClick(result): void {
    this.dialogRef.close(result);
  }
}
