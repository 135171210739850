import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { CoursesService } from 'src/app/shared/services/courses.service';
import { Category } from 'src/app/core/models/category';
import { Router } from '@angular/router';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit {

  categories: Category[];

  constructor(
    config: NgbDropdownConfig,
    private coursesService: CoursesService,
    private router: Router
  ) {
    config.placement = 'auto';
    config.autoClose = true;
    this.categories = coursesService.getAllCategories();
  }


  ngOnInit(): void {
  }

  onSelectCategory(id: number){
    this.router.navigate(['/category/'+id]);
  }

}
