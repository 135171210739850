import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AssetsObject, Course } from "src/app/core/models/course";
import { CourseContent } from "src/app/core/models/coursecontent";
import { CoursesService } from "src/app/shared/services/courses.service";
import { CartService } from "src/app/core/services/cart.service";
import { CoursesContentService } from "src/app/shared/services/courseContent.service";
import { OwlOptions } from "ngx-owl-carousel-o";
import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
//import * as S3 from "aws-sdk/clients/s3";

@Component({
  selector: "app-course-overview",
  templateUrl: "./course-overview.component.html",
  styleUrls: ["./course-overview.component.css"],
})
export class CourseOverviewComponent implements OnInit {
  course: Course;
  added = false;
  content: CourseContent;
  numberOfLessons = 0;
  assetsObj: AssetsObject[] = [];
  assetsNum: number = 0;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ["", ""],
    nav: true,
    autoHeight: true,
    margin: 450,
    rtl: true,
  };

  constructor(
    private route: ActivatedRoute,
    private courseService: CoursesService,
    private courseContent: CoursesContentService,
    private cartService: CartService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = params.id;
      this.courseService.getCourseById(id).subscribe((course) => {
        this.course = course.CourseDetailList[0].CourseDetail;
        //this.getVideos();
        this.cartService
          .getUserCartDetails(
            JSON.parse(localStorage.getItem("edGlobuserInfo")).ID
          )
          .subscribe((x) => {
            this.checkIfInCart();
          });
      });
      this.courseContent.getCourseContentById(id).subscribe((response) => {
        this.content = response;
        this.numberOfLessons = 0;
        this.content.CourseTopicList.forEach((topic) => {
          this.numberOfLessons += topic.CourseLessons.length;
        });
      });
    });
  }

  checkIfInCart() {
    const courseIndex = this.cartService.cartItems.findIndex(
      (course) => course.CourseId == this.course.ID
    );
    if (courseIndex != -1) {
      this.added = true;
    } else {
      this.added = false;
    }
  }

  addToCart() {
    const { ID: userId } = JSON.parse(localStorage.getItem("edGlobuserInfo"));
    const { ID: courseId, Fee } = this.course;
    if (userId) {
      this.cartService.addCourse(userId, courseId, 1, Fee, 0).subscribe((x) => {
        this.checkIfInCart();
      });
    } else {
      this.router.navigate(["/auth/login"]);
    }
  }
}
